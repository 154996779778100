
export type ProcessingNodeSetup = {
  Id: string
  Name: string
  ProcessingTimeout: number
  ProcessingTries: number
  ProcessingTriesPauseSeconds: number
  ParallelTasks: number
  OutputsCount: number
  ProcessingServerId: string
};

export type ProcessingServer = {
  Id: string;
  Title: string;
  PortrainerAdminPassword?: string;
  PortrainerPort: number;
  PortrainerEnabled: boolean;
  AbsoluteScriptPath: string;
  Host: string;
  CreationDate: Date;
  Username: string;
  SshPassword?: string
  LocalServer: boolean;
  RemoteWsPort: number
}

export type IntegrationSystem = {
  Id: string;
  Title: string;
  CreationDate: Date;
}




// Enums
export enum InputTypes {
  Request = 'Request',
  RequestWithResponse = 'RequestWithResponse'
}

export enum ProcessingTypes {
  Python = 'Python',
  None = 'None'
}

export enum OutputTypes {
  AddToQueue = 'AddToQueue',
  None = 'None'
}

// Interfaces
export type InputSetup = {
  Id: string;
  Tag: string;
  Name: string;
  InputType: InputTypes;
  OutputSetups?: OutputSetup[];
  ResponseTimeoutSeconds: number;
  AddedDate: Date;
  SystemIds: string[];
}

export type OutputSetup = {
  Id: string;
  Tag: string;
  Name: string;
  InputSetupId: string;
  InputSetup: InputSetup;
  ProcessingType: ProcessingTypes;
  OutputType: OutputTypes;
  ProcessingTries: number;
  ProcessingTriesPauseSeconds: number;
  ParallelTasks: number;
  EditorPort: number;
  DeployDate?: Date;
  AddedDate: Date;
  ProcessingServer?: ProcessingServer;
  ProcessingServerId?: string;
  SystemIds: string[];

  ProcessingTemplateId: string
  ProcessingTemplate: ProcessingTemplate
}


export type InputData = {
  Id: string;
  InputSetup: InputSetup;
  InputSetupId: string;
  CreationDate: Date;
  OutputDatasCreationDate?: Date;
  InputHttpMethod?: string;
  InputHttpUrl?: string;
  OutputDatas?: OutputData[];
  IpAddr?: string; // Store IP address as string

  InputHttpHeadersDict: { [key: string]: string } | undefined
  InputHttpBodyBytes: Uint8Array | undefined

  BodyFileNameFromHeaders: {
    ContentType: string
    Filename: string
  } | undefined
  ContentLength?: number
  Tags?: string[]
  DocumentId?: string
}

export class InputDataHelper {
  public static GetInto(inputData: InputData) {
    var finishDate = undefined
    var finishDates = inputData.OutputDatas?.map(od => od.FinishProcessingDate?.getTime() ?? -Infinity).filter(d => d != -Infinity) ?? [];
    if (finishDates.length == inputData.OutputDatas?.length)
      finishDate = Math.max(...finishDates)
    var processTimes = inputData.OutputDatas?.map(od => od.ProcessingTimeMs ?? -Infinity).filter(d => d != -Infinity) ?? [];
    var processTimeMs = Math.max(...processTimes)
    var isSuccess = inputData.OutputDatas?.filter(d => d.ProcessingFailure).length == 0;
    var textState = finishDate ? (!isSuccess ?
      <span className="text-danger">Сбой</span>
      :
      <span className="text-success">Успешно</span>)
      :
      <span className="text-warning">Выполняется</span>
    return { finishDate, processTimeMs, isSuccess, textState }
  }
}

export type ProcessingTemplate = {
  Id: string;
  CreationDate: Date;
  Title: string;
  Dockerfile: string;
}

export type OutputData = {
  Id: string;
  InputDataId: string;
  InputData?: InputData;
  OutputSetupId: string;
  OutputSetup: OutputSetup;
  StartProcessingDate?: Date;
  FinishProcessingDate?: Date;
  ProcessingTimeMs?: number;
  ProcessingContentLength?: number;
  SendedDate?: Date;
  AttemptNumber: number;
  ProcessingFailure: boolean;
  ProcessingError?: string;
  ProcessingHttpCode?: number;
  OutputHttpCode?: number;

  ProcessingHttpBodyBytes: Uint8Array | undefined
  ProcessingOutputStr: string | undefined

  ProcessingHttpHeadersDict: { [key: string]: string } | undefined
  ProcessingBodyFileNameFromHeaders: {
    ContentType: string
    Filename: string
  } | undefined
  Tags?: string[]
}






export type Flow = {
  Id: string
  Title: string
  Active: boolean
  Order: number
  X: number;
  Y: number;
  Zoom: number;
}
export type User = {
  Id: string
  Fio: string
  Enabled: boolean
  PhoneNumber: string | undefined
  PhoneNumberConfirmed: boolean
  UserName: string
  Email: string | undefined
  EmailConfirmed: boolean
}

export type NodeProp = {
  Title: string;
  Value: string;
};

export type SelectedProduct = {
  productId: string | undefined;
  count: number;
  price: number;
  storeId: string | undefined;
  place: string | undefined;
};

// export type StoreItem = {
//   Id: string
//   ProductId: string
//   Product: Product
//   CreateOperationComplited: boolean
//   StoreId: string
//   Store: Store
//   StorePlace: string
//   Price: number
// }
export type Operation = {
  Id: string
  StartDate: string
  FinishDate: string
  //CreatedStoreItems: StoreItem[]
  //BlockedStoreItems: StoreItem[]
  OpTitle: string
  CreatedUserId: string
  CreatedUser: User
  OpertionType: number
}


export function clone<T>(a: T): T {
  return JSON.parse(JSON.stringify(a));
}
