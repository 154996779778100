import styles from "../components/LoginTemplate.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import AdminTemplate from "../AdminTemplate";
import { useContext, useState } from "react";
import { AuthContext } from "../../models/AuthContextProvider";



export function Register() {
  let navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [fio, setFio] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [disabled, setDisabled] = useState<boolean>();
  let context = useContext(AuthContext);

  return (
    <AdminTemplate>
      <Container style={{ maxWidth: '40rem' }}>
        <form className="mt-4 p-3 bg-white shadow rounded" onSubmit={async (e) => {
          e.preventDefault();
          context.setLoading(true)
          try {
            const response = await fetch('/Users/Register?' + new URLSearchParams({
              username: login,
              password: password,
              fio: fio
            }), {
              method: 'POST'
            })
            console.log('Register result', response)
            var { Succeeded, Errors, error } = await response.json() as {
              Succeeded: boolean, Errors?: {
                Code: string, Description: string
              }[],
              error?: string
            }
            if (Succeeded) {
              alert('Пользователь зарегистрирован');
              if (await context.reload())
                navigate('/')
              else
                navigate('/account/login')
            }
            else {
              context.showMessage(Errors?.map(e => e.Description).join(', ') ?? error ?? 'Ошибка', true)
            }
          } catch (e) {
            context.showMessage('Ошибка ' + e, true)
          } finally {
            context.setLoading(false)
          }
        }}>
          <div className='mb-3 fs-5'>Регистрация</div>

          <FloatingLabel label="Логин" className="mb-3 has-validation">
            <Form.Control type="text" placeholder="Логин" required
              value={login} onChange={e => setLogin(e.target.value)}
            />
            {error && <div className='invalid-feedback'>{error}</div>}
          </FloatingLabel>

          <FloatingLabel label="ФИО" className="mb-3 has-validation">
            <Form.Control type="text" placeholder="ФИО" required
              value={fio} onChange={e => setFio(e.target.value)}
            />
            {error && <div className='invalid-feedback'>{error}</div>}
          </FloatingLabel>


          <FloatingLabel label="Пароль" className="mb-3">
            <Form.Control type="password" placeholder="Пароль" required
              value={password} onChange={e => setPassword(e.target.value)} />
          </FloatingLabel>

          <Button type="submit" variant="primary" className="mt-3 w-100" disabled={disabled}>Зарегистрироваться</Button>
          <Link to="/account/login" className="btn btn-outline-primary mt-2 w-100 btn-lg">Вход</Link>
        </form>
      </Container>
    </AdminTemplate>
  );

}