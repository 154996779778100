import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import { AuthContext } from "../../models/AuthContextProvider";
import { InputData, InputDataHelper } from "../../models/Models";
import AdminTemplate from "../AdminTemplate";
import moment from "moment";
import { Button, ButtonGroup, Dropdown, DropdownButton, Pagination, Tab, Tabs } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { handleDates } from "../../models/handleDates";
import { OpenIDE } from "../dev/Integration";

const downloadFileFromBytes = (bodyBytes: Uint8Array | undefined, filename: {
    ContentType: string
    Filename: string
} | undefined) => {
    if (!bodyBytes || !filename) {
        console.error("Missing data for file download.");
        return;
    }

    const { ContentType, Filename } = filename;
    const blob = new Blob([bodyBytes], { type: ContentType });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Filename);

    // Append to the body, click, then remove to avoid memory leaks
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export function Message(props: {
    inputDataId: string
    setInputDataId: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
    let context = useContext(AuthContext);
    var navigate = useNavigate();
    const [model, setModel] = useState<InputData | undefined>(undefined);
    const [tabKey, setTabKey] = useState<string | undefined>('inputData');
    const reload = async () => {
        context.setLoading(true);
        try {
            var r = await fetch(`/Monitoring/InputData/${props.inputDataId}`);
            var { data, error } = handleDates(await r.json()) as { data?: InputData, error?: string };
            if (error) throw error
            if (data) setModel(data)
            console.log('loaded inputData', data)
        }
        catch (error) {
            context.showMessage(error as string, true)
        }
        finally {
            context.setLoading(false)
        }
    }

    useEffect(() => { reload() }, [props.inputDataId]);
    if (!model) return <></>
    var { finishDate, processTimeMs, isSuccess } = InputDataHelper.GetInto(model);
    const tabsClass = 'rounded-bottom-3 overflow-hidden mb-3 bg-white p-3'
    return <div className="position-relative pt-2">
        <Button onClick={() => props.setInputDataId(undefined)} variant="light" style={{ position: 'absolute', top: '0rem', right: '0rem' }}><Icon.XLg /></Button>
        <Tabs
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k ?? 'main')}
            id="message-tabs"
        >
            <Tab eventKey="inputData" title="Запрос и тело" className={tabsClass} tabClassName={tabKey == 'inputData' ? 'bg-white' : ''}>
                <table className="table table-sm">
                    <tbody>
                        <tr><th style={{ minWidth: '13rem', width: '13rem' }}>ID входящего интерфейса</th><td>{model.InputSetupId}</td></tr>
                        <tr><th>Входящее сообщение</th><td>{model.Id}</td></tr>
                        <tr><th>Запрос</th><td>{model.InputHttpUrl}</td></tr>
                        <tr><th>Дата создания</th><td>{moment.tz(model.CreationDate, 'UTC').local().format('DD.MM.YYYY HH:mm:ss.SSS')}</td></tr>
                        <tr><th>Тип запроса</th><td>{model.InputHttpMethod}</td></tr>
                        <tr><th>Заголовки</th>
                            <td>
                                {model.InputHttpHeadersDict && Object.entries(model.InputHttpHeadersDict).map(([key, value]) => (
                                    <div style={{ overflow: 'hidden' }}><strong>{key}:</strong> {value}</div>
                                ))}
                                {!model.InputHttpHeadersDict && <li>Нет заголовков</li>}

                            </td></tr>
                        <tr><th>Имя файла из заголовков</th><td>
                            {model.BodyFileNameFromHeaders ?
                                <>
                                    <div><strong>Content-Type:</strong> {model.BodyFileNameFromHeaders.ContentType}</div>
                                    <div><strong>Filename:</strong> {model.BodyFileNameFromHeaders.Filename}</div>
                                </> :
                                'Не определено'
                            }
                        </td></tr>
                        <tr><th>Тело запроса</th><td>
                            {model.BodyFileNameFromHeaders && model.InputHttpBodyBytes && model.InputHttpBodyBytes.byteLength != 0 &&
                                <Button onClick={() => downloadFileFromBytes(model.InputHttpBodyBytes, model.BodyFileNameFromHeaders)}>{model.BodyFileNameFromHeaders.Filename} (байт: {model.InputHttpBodyBytes.byteLength})</Button>}
                        </td></tr>
                        <tr><th>Время обработки (мс)</th><td>{processTimeMs ?? ''}</td></tr>
                        <tr><th>Успешно</th><td>{isSuccess ? 'Да' : 'Нет'}</td></tr>
                        <tr><th>IP Address</th><td>{model.IpAddr}</td></tr>
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey="outputDatas" title="Выходные интерфейсы" className={tabsClass} tabClassName={tabKey == 'outputDatas' ? 'bg-white' : ''}>

                <table className="table m-0 align-middle table-hover">
                    <thead>
                        <tr>
                            <th>Идентификатор</th>
                            <th>Дата начала обработки</th>
                            <th>Дата окончания обработки</th>
                            <th>Время обработки (мс)</th>
                            <th>Дата отправки</th>
                            <th>Номер попытки</th>
                            <th>Ошибка обработки</th>
                            <th>Код ответа обработчика</th>
                            <th>Код ответа исходящего запроса</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.OutputDatas?.map((data) => (
                            <tr key={data.Id}>
                                <td>{data.Id}</td>
                                <td>{data.StartProcessingDate ? moment.tz(data.StartProcessingDate, 'UTC').local().format('DD.MM.YYYY HH:mm:ss.SSS') : ''}</td>
                                <td>{data.FinishProcessingDate ? moment.tz(data.FinishProcessingDate, 'UTC').local().format('DD.MM.YYYY HH:mm:ss.SSS') : ''}</td>
                                <td>{data.ProcessingTimeMs}</td>
                                <td>{data.SendedDate ? moment.tz(data.SendedDate, 'UTC').local().format('DD.MM.YYYY HH:mm:ss.SSS') : ''}</td>
                                <td>{data.AttemptNumber}</td>
                                <td>{data.ProcessingFailure ? 'Да' : 'Нет'}</td>
                                <td>{data.ProcessingHttpCode ?? ''}</td>
                                <td>{data.OutputHttpCode ?? ''}</td>
                                <td>
                                    <Button variant="white" onClick={() => OpenIDE(context, data.OutputSetupId, data.Id)}>
                                        <Icon.CodeSlash height={20} width={20} /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey="logs" title="Журнал обработки" className={tabsClass} tabClassName={tabKey == 'logs' ? 'bg-white' : ''}>
                <Tabs
                    id="out-details-tabs"
                    variant="underline"
                >
                    {model.OutputDatas?.map(outputData => {
                        var outputStatus = outputData.FinishProcessingDate ? `${outputData.ProcessingFailure ? 'Сбой' : 'Успешно'}` : 'Выполняется';
                        return <Tab className="py-3" eventKey={outputData.OutputSetupId} title={`${outputData.OutputSetup.Name} (${outputStatus})`}>

                            <div className="mb-2">
                                <Button variant="primary" onClick={() => OpenIDE(context, outputData.OutputSetupId, outputData.Id)}>
                                    Отладка <Icon.CodeSlash height={20} width={20} /></Button>
                            </div>
                            <table className="table table-sm">
                                <tbody>
                                    <tr><th style={{ width: '10rem' }}>Статус выполнения</th><td>{outputStatus}</td></tr>

                                    <tr>
                                        <th>Вывод обработчика</th>
                                        <td>
                                            {(!outputData.ProcessingError || outputData.ProcessingError?.length == 0) && <>Нет</>}
                                            {(outputData.ProcessingError?.length ?? 0) > 0 && <>
                                                <code>{outputData.ProcessingError?.trim()}</code>
                                            </>}
                                        </td></tr>
                                    <tr>
                                        <th>Вывод обработчика</th>
                                        <td>
                                            <code>{outputData.ProcessingOutputStr}</code>
                                        </td>
                                    </tr>
                                    <tr><th>Код ответа обработчика</th><td>{outputData.ProcessingHttpCode}</td></tr>

                                    <tr><th>Заголовки</th>
                                        <td>
                                            {outputData.ProcessingHttpHeadersDict && Object.entries(outputData.ProcessingHttpHeadersDict).map(([key, value]) => (
                                                <div style={{ overflow: 'hidden' }}><strong>{key}:</strong> {value}</div>
                                            ))}
                                            {!outputData.ProcessingHttpHeadersDict && <li>Нет заголовков</li>}

                                        </td></tr>
                                    <tr><th>Имя файла из заголовков</th><td>
                                        {outputData.ProcessingBodyFileNameFromHeaders ?
                                            <>
                                                <div><strong>Content-Type:</strong> {outputData.ProcessingBodyFileNameFromHeaders.ContentType}</div>
                                                <div><strong>Filename:</strong> {outputData.ProcessingBodyFileNameFromHeaders.Filename}</div>
                                            </> :
                                            'Не определено'
                                        }
                                    </td></tr>
                                    <tr><th>Тело запроса</th><td>
                                        {outputData.ProcessingBodyFileNameFromHeaders && outputData.ProcessingHttpBodyBytes && outputData.ProcessingHttpBodyBytes.byteLength != 0 &&
                                            <Button onClick={() => downloadFileFromBytes(outputData.ProcessingHttpBodyBytes, outputData.ProcessingBodyFileNameFromHeaders)}>
                                                {outputData.ProcessingBodyFileNameFromHeaders.Filename} (байт: {outputData.ProcessingHttpBodyBytes.byteLength})</Button>}
                                    </td></tr>

                                </tbody>
                            </table>
                        </Tab>
                    })}
                </Tabs>
            </Tab>
        </Tabs>
    </div>
}