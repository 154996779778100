import { parseISO } from "date-fns/parseISO";

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
    return typeof value === "string" && ISODateFormat.test(value);
};

const base64ToArrayBuffer = (base64: string) => {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export const handleDates = (data: unknown) => {
    if (isIsoDateString(data)) return parseISO(data);
    if (data === null || data === undefined || typeof data !== "object") return data;

    for (const [key, val] of Object.entries(data)) {
        // @ts-expect-error this is a hack to make the type checker happy
        if (isIsoDateString(val)) data[key] = parseISO(val);
        // @ts-expect-error this is a hack to make the type checker happy
        else if (key.endsWith('Bytes') && typeof val === "string") data[key] = base64ToArrayBuffer(val);
        else if (typeof val === "object") handleDates(val);
    }

    return data
};

