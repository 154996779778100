import * as Icon from 'react-bootstrap-icons';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import { AuthContext } from "../../models/AuthContextProvider";
import AdminTemplate from "../AdminTemplate";
import { Message } from "./Message";
import { PeriodSelector } from "./PeriodSelector";
import { GroupsTable } from "./GroupsTable";
import { Messages } from "./Messages";
import moment from "moment";
import { Button } from "react-bootstrap";
import { HeaderPage } from '../HeaderPage';



export interface GroupInfo {
    Id: string,
    Key: any,
    MaxCreationDate: Date | undefined
    Count: number
    FailureCount: number
    SuccessCount: number
    PlanedCount: number
}


export const filtersDefaults: SearchParamsStateType = {
    Page: { type: 'number', default: 1 },
    PerPage: { type: 'number', default: 30 },

    FilterInputHttpUrl: { type: 'string', default: '' }, // Добавлен фильтр по адресу запроса
    FilterResult: { type: 'string', default: '' }, // Добавлен фильтр по результату
    FilterInputIface: { type: 'string', default: '' },
    FilterOutputIface: { type: 'string', default: '' },
    FilterProcessingServer: { type: 'string', default: '' },
    FilterRequestMethod: { type: 'string', default: '' },
    FilterSenderIp: { type: 'string', default: '' },
    FilterProcessTime: { type: 'string', default: '' },
    FilterPeriodStart: { type: 'number', default: -1 },
    FilterPeriodEnd: { type: 'number', default: -1 },

    OrderField: { type: 'string', default: 'CreationDate' },
    OrderDirection: { type: 'string', default: 'desc' },
    GroupBy: { type: 'string', default: 'None' },
    GroupsPeriodStart: { type: 'number', default: moment().unix() - 3600 * 24 },
    GroupsPeriodEnd: { type: 'number', default: moment().unix() },
    SelectedGroupId: { type: 'string', default: '' }

};


export function Monitoring() {
    let context = useContext(AuthContext);
    var navigate = useNavigate();
    const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
    const [detailedInputDataId, setDetailedInputDataId] = useState<string | undefined>(undefined);
    const [groupsTableHeight, setGroupsTableHeight] = useState<number>(JSON.parse(window.localStorage['groupsTableHeight'] || '100') || 100);
    const [messagesTableHeight, setMessagesTableHeight] = useState<number>(JSON.parse(window.localStorage['messagesTableHeight'] || '100') || 100);

    useEffect(() => { window.localStorage['groupsTableHeight'] = groupsTableHeight; }, [groupsTableHeight]);
    useEffect(() => { window.localStorage['messagesTableHeight'] = messagesTableHeight; }, [messagesTableHeight]);


    return <AdminTemplate>
        <div className="p-3">
            <HeaderPage title="Мониторинг" />
            <div className="d-flex p-2 w-100 justify-content-end align-items-center">
                <div className="d-flex flex-row align-items-center gap-2">
                    Период:
                    <PeriodSelector
                        periodStart={filterParams.GroupsPeriodStart}
                        periodEnd={filterParams.GroupsPeriodEnd}
                        setPeriodStart={(unix) => setFilterParams({ GroupsPeriodStart: unix.toString() })}
                        setPeriodEnd={(unix) => setFilterParams({ GroupsPeriodEnd: unix.toString() })} />
                </div>
            </div>

            <GroupsTable
                groupsTableHeight={groupsTableHeight}
                filterParams={filterParams}
                setFilterParams={setFilterParams} />

            {filterParams.SelectedGroupId && filterParams.SelectedGroupId != '' && <>
                <hr className="py-1 border-0" style={{ cursor: 'row-resize', backgroundColor: '#DEE1FF', opacity: 1 }} onMouseDown={(mouseDownEvent) => {
                    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
                    var onMouseMove = (mouseMoveEvent: MouseEvent) => setGroupsTableHeight(() => (groupsTableHeight - startPosition.y + mouseMoveEvent.pageY));
                    var onMouseUp = () => document.body.removeEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mouseup", onMouseUp, { once: true });
                }} />

                <Messages
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    detailedInputDataId={detailedInputDataId}
                    setDetailedInputDataId={setDetailedInputDataId}
                    messagesTableHeight={messagesTableHeight} />



                {detailedInputDataId && <><hr className="py-1 border-0" style={{ cursor: 'row-resize', backgroundColor: '#DEE1FF', opacity: 1 }} onMouseDown={(mouseDownEvent) => {
                    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
                    var onMouseMove = (mouseMoveEvent: MouseEvent) => setMessagesTableHeight(() => (messagesTableHeight - startPosition.y + mouseMoveEvent.pageY));
                    var onMouseUp = () => document.body.removeEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mousemove", onMouseMove);
                    document.body.addEventListener("mouseup", onMouseUp, { once: true });
                }} />
                    <Message inputDataId={detailedInputDataId} setInputDataId={setDetailedInputDataId}></Message></>}

            </>}
        </div>
    </AdminTemplate>;
}

