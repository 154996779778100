import { useContext, useState, useEffect } from "react";
import React from "react";
import Moment from "react-moment";
import 'moment/locale/ru';
import { Button, Col, FloatingLabel, Form, InputGroup, Modal, ProgressBar, Row, Tab, Tabs } from 'react-bootstrap';
import { Hint, Typeahead } from "react-bootstrap-typeahead";
import { TypeaheadInputProps } from "react-bootstrap-typeahead/types/types";
import { useNavigate } from "react-router-dom";
import AdminTemplate from "../AdminTemplate";
import { AuthContext } from "../../models/AuthContextProvider";
import { User } from "../../models/Models";

export function Settings() {
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    const [me, setMe] = useState<User>(context.me ?? {
        Enabled: true, Fio: '', IsAdmin: false, UserName: '', Id: '', TicketTypes: [], Workgroups: [],
        PhoneNumberConfirmed: false, PhoneNumber: undefined, Email: undefined, EmailConfirmed: false,
    } as User);
    const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
    const [emailMessage, setEmailMessage] = useState<string | undefined>(undefined);
    const [smsCode, setSmsCode] = useState<string>('');
    const [emailCode, setEmailCode] = useState<string>('');



    const sendSmsCode = async () => {
        context.setLoading(true)
        setSmsMessage(undefined)
        try {
            const response = await fetch('/Users/SendSmsCode', {
                method: 'POST',
            })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setSmsMessage(message)
            }
            else {
                context.showMessage(error ?? 'Ошибка', true)
            }
        } catch (e) {
            context.showMessage('Ошибка ' + e, true)
        } finally {
            context.setLoading(false)
        }
    }

    const confirmSmsCode = async () => {
        context.setLoading(true)
        try {
            if (!(smsCode?.length > 0)) {
                context.showMessage("Введите код", false)
                return
            }
            const response = await fetch('/Users/ConfirmSmsCode?' + new URLSearchParams({ code: smsCode }),
                {
                    method: 'POST',
                })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setSmsMessage(undefined)
                context.showMessage(message, false)
                var newContext = await context.reload()
                if (newContext?.me)
                    setMe(newContext.me)
                else
                    navigate('/account/login')
            }
            else {
                setSmsMessage(error ?? 'Ошибка')
            }
        } catch (e) {
            setSmsMessage(`Ошибка ${e}`)
        } finally {
            context.setLoading(false)
        }
    }


    const sendEmailCode = async () => {
        context.setLoading(true)
        setEmailMessage(undefined)
        try {
            const response = await fetch('/Users/SendEmailCode', {
                method: 'POST',
            })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setEmailMessage(message)
            }
            else {
                context.showMessage(error ?? 'Ошибка', true)
            }
        } catch (e) {
            context.showMessage('Ошибка ' + e, true)
        } finally {
            context.setLoading(false)
        }
    }

    const confirmEmailCode = async () => {
        context.setLoading(true)
        try {
            if (!(emailCode?.length > 0)) {
                context.showMessage("Введите код", false)
                return
            }
            const response = await fetch('/Users/ConfirmEmailCode?' + new URLSearchParams({ code: smsCode }),
                {
                    method: 'POST',
                })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setEmailMessage(undefined)
                context.showMessage(message, false)
                var newContext = await context.reload()
                if (newContext?.me)
                    setMe(newContext.me)
                else
                    navigate('/account/login')
            }
            else {
                setEmailMessage(error ?? 'Ошибка')
            }
        } catch (e) {
            setEmailMessage(`Ошибка ${e}`)
        } finally {
            context.setLoading(false)
        }
    }

    return (<AdminTemplate>
        <Row className="m-4">
            <div className="p-4 bg-white rounded-3 mb-3 mx-auto" style={{ maxWidth: "30rem" }}>
                <h5 className="mb-4">Настройки</h5>
                {/* <code>{JSON.stringify(me)}</code> */}

                <form onSubmit={async (e) => {
                    e.preventDefault();
                    context.setLoading(true)
                    try {
                        const response = await fetch('/Users/SaveMe', {
                            method: 'POST',
                            body: JSON.stringify(me),
                            headers: { 'Content-Type': 'application/json' }
                        })
                        console.log('Register result', response)
                        var { message, error } = await response.json() as {
                            message?: string
                            error?: string
                        }
                        if (message) {
                            if (message != 'OK') context.showMessage(message, false)
                            var newContext = await context.reload()
                            if (newContext?.me)
                                setMe(newContext.me)
                            else
                                navigate('/account/login')
                        }
                        else {
                            context.showMessage(error ?? 'Ошибка', true)
                        }
                    } catch (e) {
                        context.showMessage('Ошибка ' + e, true)
                    } finally {
                        context.setLoading(false)
                    }
                }}>

                    <FloatingLabel label="ФИО" className="mb-3 has-validation">
                        <Form.Control type="text" placeholder="ФИО" required
                            value={me.Fio} onChange={e => setMe({ ...me, Fio: e.target.value })}
                        />
                    </FloatingLabel>

                    <div className="mb-3 d-flex" style={{ gap: '1rem' }}>
                        <FloatingLabel label="Телефон" className="has-validation w-100">
                            <Form.Control type="text" placeholder="Телефон"
                                value={me.PhoneNumber} onChange={e => setMe({ ...me, PhoneNumber: e.target.value })}
                            />
                        </FloatingLabel>
                        {((me.PhoneNumber?.length ?? 0) > 0 && me.PhoneNumber == context.me?.PhoneNumber && !me.PhoneNumberConfirmed) && <>
                            <Button onClick={e => sendSmsCode()} variant="danger">Подтвердить по SMS</Button>
                        </>}
                    </div>

                    <div className="mb-3 d-flex" style={{ gap: '1rem' }}>
                        <FloatingLabel label="E-mail" className="has-validation w-100">
                            <Form.Control type="text" placeholder="E-mail"
                                value={me.Email} onChange={e => setMe({ ...me, Email: e.target.value })}
                            />
                        </FloatingLabel>
                        {((me.Email?.length ?? 0) > 0 && me.Email == context.me?.Email && !me.EmailConfirmed) && <>
                            <Button onClick={e => sendEmailCode()} variant="danger">Подтвердить E-mail</Button>
                        </>}
                    </div>


                    <Button type="submit" variant="primary" size="lg" className="mt-3 w-100" disabled={context.isLoading}>Сохранить</Button>
                </form>
            </div>
        </Row>

        {smsMessage && <>
            <Modal show={smsMessage != undefined} onHide={() => setSmsMessage(undefined)} centered size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Введите SMS-код</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">{smsMessage}</div>
                    <FloatingLabel label="SMS код" className="has-validation w-100">
                        <Form.Control placeholder="SMS код" value={smsCode} required
                            onChange={(e) => setSmsCode(e.target.value)} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setSmsMessage(undefined)}>Отмена</Button>
                    <Button variant="primary" className="ms-2" onClick={() => confirmSmsCode()}>Отправить</Button>
                </Modal.Footer>
            </Modal>
        </>}



        {emailMessage && <>
            <Modal show={emailMessage != undefined} onHide={() => setEmailMessage(undefined)} centered size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Введите код из E-Mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">{emailMessage}</div>
                    <FloatingLabel label="E-Mail код" className="has-validation w-100">
                        <Form.Control placeholder="E-Mail код" value={emailCode} required
                            onChange={(e) => setEmailCode(e.target.value)} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setEmailMessage(undefined)}>Отмена</Button>
                    <Button variant="primary" className="ms-2" onClick={() => confirmEmailCode()}>Отправить</Button>
                </Modal.Footer>
            </Modal>
        </>}

    </AdminTemplate>)
}