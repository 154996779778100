import { Modal, FloatingLabel, Button, Form, Container, Row, InputGroup } from "react-bootstrap"
import { IntegrationSystem, OutputSetup, OutputTypes, ProcessingTypes } from "../../models/Models"
import { AuthContext } from "../../models/AuthContextProvider";
import { useContext, useState } from "react";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

export function OutputInterfaceEditor(props: {
    editor: OutputSetup
    setEditor: React.Dispatch<React.SetStateAction<OutputSetup | undefined>>
    reload: () => void
}) {
    let context = useContext(AuthContext);
    const [integrationSystems, setIntegrationSystems] = useState<IntegrationSystem[]>(() => context.environment.integrationSystems.filter(s => props.editor.SystemIds.includes(s.Id)));

    const update = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        context.setLoading(true);
        var isNewIntegrationSystem = false;
        try {
            // добавление всех новых интеграционных систем
            console.log('Выбранные интеграционные системы', integrationSystems)
            var systemIds = [];
            for (let i = 0; i < integrationSystems.length; i++) {
                const system = integrationSystems[i] as IntegrationSystem
                if ((system as any).customOption) {
                    var newSystemResult = await fetch(`/Systems/Save`, { method: 'POST', body: JSON.stringify({ Title: system.Title, Id: crypto.randomUUID() }), headers: { 'Content-Type': 'application/json' } });
                    const { message, error, edit } = await newSystemResult.json() as { message?: string, error?: string, edit?: IntegrationSystem };
                    if (error) throw error;
                    if (!edit) throw 'Ошибка сохранения интеграционной системы';
                    systemIds.push(edit.Id);
                    isNewIntegrationSystem = true;
                } else
                    systemIds.push(system.Id);
            }
            // сохранение
            const response = await fetch(`/Integrations/SaveOutputInterface`, {
                method: 'POST', body: JSON.stringify({
                    ...props.editor,
                    SystemIds: systemIds
                } as OutputSetup),
                headers: { 'Content-Type': 'application/json' }
            });
            const { message, error } = await response.json() as { message?: string, error?: string };
            if (message) {
                await props.reload()
                if (message != 'OK') context.showMessage(message, false);
                else props.setEditor(undefined)
            }
            if (error) {
                context.showMessage(error, true);
            }
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
            if (isNewIntegrationSystem) await context.reloadEnvironment()
        }
    }

    const recreateContainer = async () => {
        if (!window.confirm('Точно пересоздать контейнер?')) return;
        context.setLoading(true);
        try {
            const response = await fetch(`/Integrations/RecreateOutputInterface/${props.editor.Id}`, { method: 'POST' });
            const { message, error } = await response.json() as { message?: string, error?: string };
            if (message) {
                await props.reload()
                if (message != 'OK') context.showMessage(message, false);
                else props.setEditor(undefined)
            }
            if (error) {
                context.showMessage(error, true, 'lg');
            }
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    }
    return (
        <Modal show={props.editor != undefined} onHide={() => props.setEditor(undefined)} centered size="xl">
            <Modal.Header closeButton className="mt-2">
                <div>
                    <h1 className="mt-3">{props.editor.Name}
                        <span className="badge bg-secondary ms-2">{props.editor.Tag}</span>
                        <span className="badge bg-secondary ms-2">{props.editor.Id}</span>
                    </h1>
                    <h2 className="mb-4">Выходной интерфейс</h2>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <Container>
                    <Form onSubmit={update}>
                        <Form.Group className="w-100">
                            <Form.Label>Наименование</Form.Label>
                            <Form.Control placeholder="Наименование" value={props.editor.Name} required onChange={(e) => props.setEditor(({ ...props.editor, Name: e.target.value }))} />
                        </Form.Group>

                        <InputGroup hasValidation>
                            <Form.Group className="w-100 mt-4">
                                <Form.Label>Буквенно-числовой идентификатор</Form.Label>
                                <Form.Control placeholder="Буквенно-числовой идентификатор" value={props.editor.Tag} required onChange={(e) => props.setEditor(({ ...props.editor, Tag: e.target.value }))} />
                            </Form.Group>
                            <Form.Control.Feedback type="invalid">
                                Укажите буквенно-числовой идентификатор
                            </Form.Control.Feedback>
                        </InputGroup>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Тип обработки</Form.Label>
                            <Form.Select onChange={(e) => props.setEditor(({ ...props.editor, ProcessingType: e.target.value as ProcessingTypes }))}>
                                <option value={ProcessingTypes.None} selected={props.editor.ProcessingType == ProcessingTypes.None}>Без обработки</option>
                                <option value={ProcessingTypes.Python} selected={props.editor.ProcessingType == ProcessingTypes.Python}>Python-скрипт</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Тип вывода</Form.Label>
                            <Form.Select onChange={(e) => props.setEditor(({ ...props.editor, OutputType: e.target.value as OutputTypes }))}>
                                <option value={OutputTypes.None} selected={props.editor.OutputType == OutputTypes.None}>Ничего не делать</option>
                                <option value={OutputTypes.AddToQueue} selected={props.editor.OutputType == OutputTypes.AddToQueue}>Положить в очередь и ждать пока заберут</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Потоков для обработки</Form.Label>
                            <Form.Control placeholder="Потоков для обработки" min={1} max={65535} type="number" value={props.editor.ParallelTasks} required onChange={(e) => {
                                var p = parseInt(e.target.value)
                                if (p && !isNaN(p) && p > 0 && p < 65535)
                                    props.setEditor(({ ...props.editor, ParallelTasks: p }))
                            }} />
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Сколько предпринять попыток обработки</Form.Label>
                            <Form.Control placeholder="Сколько предпринять попыток обработки" min={1} max={65535} type="number" value={props.editor.ProcessingTries} required onChange={(e) => {
                                var p = parseInt(e.target.value)
                                if (p && !isNaN(p) && p > 0 && p < 65535)
                                    props.setEditor(({ ...props.editor, ProcessingTries: p }))
                            }} />
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Пауза между попытками, сек</Form.Label>
                            <Form.Control placeholder="Пауза между попытками, сек" min={0} max={65535} type="number" value={props.editor.ProcessingTriesPauseSeconds} required onChange={(e) => {
                                var p = parseInt(e.target.value)
                                if (p && !isNaN(p) && p > 0 && p < 65535)
                                    props.setEditor(({ ...props.editor, ProcessingTriesPauseSeconds: p }))
                            }} />
                        </Form.Group>


                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Сервер обработки
                                {props.editor.ProcessingServerId && <span className="badge bg-secondary ms-2">ID {props.editor.ProcessingServerId}</span>}
                            </Form.Label>
                            <Form.Select onChange={(e) => props.setEditor(({ ...props.editor, ProcessingServerId: e.target.value }))}>
                                {!props.editor.ProcessingServerId && <option selected value={''}>Выберите сервер обработки</option>}
                                {context.environment.processingServers.map(server =>
                                    <option value={server.Id} selected={props.editor.ProcessingServerId == server.Id}>{server.Title}</option>)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Шаблон обработки
                                {props.editor.ProcessingTemplateId && <span className="badge bg-secondary ms-2">ID {props.editor.ProcessingTemplateId}</span>}
                            </Form.Label>
                            <Form.Select onChange={(e) => props.setEditor(({ ...props.editor, ProcessingTemplateId: e.target.value }))}>
                                {!props.editor.ProcessingTemplateId && <option selected value={''}>Выберите шаблон обработки</option>}
                                {context.environment.processingTemplates.map(template =>
                                    <option value={template.Id} selected={props.editor.ProcessingTemplateId == template.Id}>{template.Title}</option>)}
                            </Form.Select>
                        </Form.Group>



                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Интеграционные системы</Form.Label>
                            <Typeahead placeholder="Интеграционные системы" className="has-validation"
                                allowNew
                                multiple
                                newSelectionPrefix="Добавить новую интеграционную систему: "
                                selected={integrationSystems}
                                options={context.environment.integrationSystems}
                                labelKey='Title'
                                onChange={(selected) => setIntegrationSystems(selected.map((tt: any) => tt as IntegrationSystem))}
                            />
                        </Form.Group>

                        <div className="d-flex mt-3" style={{ gap: '1rem' }}>
                            <Button variant="primary" type="submit">Сохранить</Button>
                            <Button variant="secondary-outline" onClick={() => props.setEditor(undefined)}>Отмена</Button>
                            <Button className="ms-auto" variant="danger-outline" onClick={recreateContainer}>Пересоздать контейнер</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}