import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from './NotFound';
// import { Pokupka } from './pages/Pokupka';
// import { Invent } from './pages/Invent';
import { AuthContext, AuthContextProvider } from './models/AuthContextProvider';
import { Settings } from './pages/account/Settings';
import { Login } from './pages/account/Login';
import { Logout } from './pages/account/Logout';
import { Lost } from './pages/account/Lost';
import { Register } from './pages/account/Register';
import "@fontsource/inter"; // Defaults to weight 400.
import './App.scss';
import { HomePage } from './pages/HomePage';
import { DevsIntegrations } from './pages/dev/Integrations';
import { DevsIntegration } from './pages/dev/Integration';
import { SettingServers } from './pages/settings/Servers';
import { Monitoring } from "./pages/monitoring/Monitoring";
import { SettingSystems } from './pages/settings/Systems';
import { ProcessingTemplates } from './pages/settings/ProcessingTemplates';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ProtectedRoute = ({ children }: { children: any }) => {
  let context = useContext(AuthContext);
  if (!context?.me)
    {
      console.log('Вход не выполнен, доступ к ProtectedRoute не разрешён')
      return <Navigate to={`/account/login?url=${window.encodeURIComponent(window.location.pathname + (window.location.search ?? '') + (window.localStorage.hash ?? ''))}`} replace />;
    }
  return children;
};


root.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        {/* <Route path="/pokupka" element={<Pokupka />} /> */}
        {/* <Route path="/admin/flows" element={<FlowWithProvider />} /> */}
        {/* <Route path="/invent" element={<Invent />} /> 
          <Route path="/arm/newop" element={<ArmNewOp />} />
          <Route path="/arm/noderedui" element={<ArmNodeRedUI />} />
          <Route path="/admin/op/:opid" element={<AdminOp />} />
          <Route path="/admin/inv/:opid" element={<AdminInventarisation />} />
          <Route path="/admin/buy/:opid" element={<AdminBuy />} />
          <Route path="/admin/sell/:opid" element={<AdminSell />} />
          <Route path="/arm/ops" element={<ArmOps />} />
          <Route path="/arm/op/:opid" element={<ArmOp />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/buy" element={<BuyReport />} />
          <Route path="/reports/ostatki" element={<OstatkiReport />} />
          <Route path="/reports/fields" element={<FieldsReport />} />
          <Route path="/reports/zp" element={<ZpReport />} />
          <Route path="/cats" element={<Cats />} />
          <Route path="/cat/users" element={<CatUsers />} />
          <Route path="/cat/stores" element={<CatStores />} />
          <Route path="/reports/qrproduct/:idpart" element={<QrProductReport />} />*/}
        <Route path="/account/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />

        <Route path="/dev/integrations" element={<ProtectedRoute><DevsIntegrations /></ProtectedRoute>} />
        <Route path="/dev/input/:inputSetupId" element={<ProtectedRoute><DevsIntegration /></ProtectedRoute>} />

        <Route path="/settings/servers" element={<ProtectedRoute><SettingServers /></ProtectedRoute>} />
        <Route path="/settings/systems" element={<ProtectedRoute><SettingSystems /></ProtectedRoute>} />
        <Route path="/settings/processing-templates" element={<ProtectedRoute><ProcessingTemplates /></ProtectedRoute>} />

        <Route path="/monitor/messages" element={<ProtectedRoute><Monitoring /></ProtectedRoute>} />

        <Route path="/account/login" element={<Login />} />
        <Route path="/account/logout" element={<Logout />} />
        <Route path="/account/lost" element={<Lost />} />
        <Route path="/account/register" element={<Register />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </AuthContextProvider>
  // </React.StrictMode>
);

