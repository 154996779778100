import { FormEvent, FormEventHandler, useCallback, useContext, useEffect, useState } from "react";
import { InputSetup, InputTypes, OutputSetup, OutputTypes, ProcessingServer, ProcessingTypes } from "../../models/Models";
import AdminTemplate from "../AdminTemplate";
import { AuthContext, AuthData } from "../../models/AuthContextProvider";
import { Button, Container, Form, Modal } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { Server } from "http";
import { InputInterfaceEditor } from "../dev/InputInterfaceEditor";
import { HeaderPage } from "../HeaderPage";
import moment from 'moment-timezone'; // Используйте moment-timezone

async function Remove(context: AuthData, inputId: string, ok: () => Promise<any>) {
    console.log('Удалить сервер');
    context.setLoading(true);
    try {
        const response = await fetch(`/Servers/Delete/` + inputId, { method: 'DELETE' });
        const { message, error } = await response.json() as { message?: string, error?: string };
        if (message) {
            await ok();
            if (message != 'OK') context.showMessage(message, false);
            return true
        }
        if (error) {
            context.showMessage(error, true);
        }
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
    return false
};

export async function OpenPortrainer(context: AuthData, serverId: string) {
    context.setLoading(true);
    try {
        const response = await fetch(`/Servers/GetPortrainerLink/${serverId}`, { method: 'POST' });
        const { error, url } = await response.json() as { error?: string, url: string };
        if (error) throw error;
        console.log('Portrainer link: ', url);
        window.open(url)
    } catch (error) {
        context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
    } finally {
        context.setLoading(false);
    }
}



export function SettingServers() {
    const navigate = useNavigate();
    const [editor, setEditor] = useState<ProcessingServer | undefined>(undefined);
    type ConnectionStateItem = {
        ServerId: string;
        IsConnected: boolean;
        LastConnectionDate: Date | undefined;
        LastConnectionError: string | undefined;
        ConnectionNormal: boolean;
        CheckErrorMessage: string | undefined;
    };

    const [serversState, setServersState] = useState<ConnectionStateItem[]>([]);

    useEffect(() => {
        (async () => {
            context.setLoading(true);
            try {
                const response = await fetch(`/Servers/SshTunnelsState`);
                const j = await response.json() as any;
                if (j.error) throw j.error;
                setServersState(j);
            } catch (error) {
                context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
            } finally {
                context.setLoading(false);
            }
        })()
    }, [])
    let context = useContext(AuthContext);
    var model = context.environment.processingServers


    return (<AdminTemplate>
        <div className="p-3">
            <HeaderPage title="Серверы обработки" />
            <div>
                <Button onClick={() => setEditor({
                    Id: crypto.randomUUID(),
                    Title: 'Новый сервер',
                    AbsoluteScriptPath: '/root/pi/pi-scripts',
                    PortrainerAdminPassword: '',
                    Host: 'localhost',
                    Username: 'root',
                    PortrainerEnabled: true,
                    PortrainerPort: 8443,
                    RemoteWsPort: 9090,
                    LocalServer: true
                } as ProcessingServer)}>Добавить сервер обработки</Button>
            </div>
            {model.length == 0 && <>Пока пусто</>}
            {model.length > 0 && <div className="rounded-3 overflow-hidden mt-3">
                <table className="table m-0 align-middle table-hover">
                    <thead>
                        <tr>
                            <th>GUID сервера</th>
                            <th>Название</th>
                            <th>Дата добавления</th>
                            <th>Абсолютный путь к директории хранения файлов интерфейсов</th>
                            <th>Хост</th>
                            <th>Активен Portrainer</th>
                            <th>Порт Portrainer</th>
                            <th>Имя пользователя</th>
                            <th>Статус</th>
                            <th>Ошибка подключения</th>
                            <th>Время подключения</th>
                            <th>Тест</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.map(server => {
                            var serverState = serversState.find(s => s.ServerId == server.Id)
                            var isConnected = server.LocalServer ? true : serverState?.IsConnected ?? false;
                            return <tr>
                                <td>{server.Id}</td>
                                <td>{server.Title}</td>
                                <td>{server.CreationDate.toString()}</td>
                                <td>{server.AbsoluteScriptPath}</td>
                                <td>{server.Host}</td>
                                <td>{server.PortrainerEnabled}</td>
                                <td>{server.PortrainerPort}</td>
                                <td>{server.Username}</td>
                                <td>{isConnected ? 'Подключен' : 'Не подключен'}</td>
                                <td>{!isConnected && (serverState?.LastConnectionError ?? '-')}</td>
                                <td>{serverState?.LastConnectionDate ? moment.tz(serverState.LastConnectionDate, 'UTC').local().format('DD.MM.YYYY HH:mm') : '-'}</td>
                                <td>{serverState?.ConnectionNormal == true ? 'Тест прошел успешно' : 'Тест не прошел ' + serverState?.CheckErrorMessage}</td>
                                <td>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <Button variant='white' onClick={() => setEditor({ ...server })}>
                                            <Icon.Pencil height={15} width={15} />
                                        </Button>

                                        <Button variant="white" onClick={() => {
                                            if (!window.confirm('Точно удалить?')) return;
                                            Remove(context, server.Id, context.reloadEnvironment);
                                        }}>
                                            <Icon.Trash3 height={15} width={15} />
                                        </Button>

                                        {server.PortrainerEnabled && <Button variant='white' onClick={() => OpenPortrainer(context, server.Id)}>
                                            <Icon.WindowStack height={15} width={15} />
                                        </Button>}
                                    </div>
                                </td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
        {editor && <Editor editor={editor} setEditor={setEditor} reload={context.reloadEnvironment}></Editor>}
    </AdminTemplate>
    );
}


function Editor(props: {
    editor: ProcessingServer
    setEditor: React.Dispatch<React.SetStateAction<ProcessingServer | undefined>>
    reload: () => void
}) {
    let context = useContext(AuthContext);

    const update = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        context.setLoading(true);
        try {
            const response = await fetch(`/Servers/Save`, { method: 'POST', body: JSON.stringify(props.editor), headers: { 'Content-Type': 'application/json' } });
            const { message, error } = await response.json() as { message?: string, error?: string };
            if (message) {
                await props.reload()
                if (message != 'OK') context.showMessage(message, false);
                else props.setEditor(undefined)
            }
            if (error) context.showMessage(error, true);
            else await context.reloadEnvironment();
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    }
    const [sshKey, setSshKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        (async () => {
            context.setLoading(true);
            try {
                const response = await fetch(`/Servers/SshPubKey`);
                const { error, pub } = await response.json() as { error?: string, pub: string };
                if (error) throw error;
                setSshKey(pub);
            } catch (error) {
                context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
            } finally {
                context.setLoading(false);
            }
        })();
    }, [])

    return (
        <Modal show={props.editor != undefined} onHide={() => props.setEditor(undefined)} centered size="xl">
            <Modal.Header closeButton className="mt-2">

                <div>
                    <h1 className="mt-3">{props.editor.Title}</h1>
                    <h2 className="mb-4">Сервер обработки</h2>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <Container>
                    <Form onSubmit={update}>


                        <Form.Group className="has-validation w-100">
                            <Form.Label>GUID</Form.Label>
                            <Form.Control value={props.editor.Id} readOnly disabled />
                        </Form.Group>

                        <Form.Group className="has-validation w-100 mt-4">
                            <Form.Label>Название</Form.Label>
                            <Form.Control placeholder="Название" value={props.editor.Title} required onChange={(e) => props.setEditor(({ ...props.editor, Title: e.target.value }))} />
                        </Form.Group>

                        <Form.Group className="has-validation w-100 mt-4">
                            <Form.Label>Абсолютный путь к директории хранения файлов интерфейсов</Form.Label>
                            <Form.Control placeholder="Например: /root/pi/pi-scripts" value={props.editor.AbsoluteScriptPath} required onChange={(e) => props.setEditor(({ ...props.editor, AbsoluteScriptPath: e.target.value }))} />
                        </Form.Group>



                        <h2 className="mt-5">Управление контейнерами Portrainer</h2>
                        <Form.Check // prettier-ignore
                            className="mt-4"
                            type="switch"
                            label='Активен Portrainer'
                            checked={props.editor.PortrainerEnabled}
                            onChange={(e) => props.setEditor(({ ...props.editor, PortrainerEnabled: e.target.checked }))}
                        />

                        {props.editor.PortrainerEnabled && <>
                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Порт Portrainer</Form.Label>
                                <Form.Control placeholder="8888" value={props.editor.PortrainerPort} type="number" required onChange={(e) => {
                                    var p = parseInt(e.target.value)
                                    if (p && !isNaN(p) && p > 0 && p < 65535) props.setEditor(({ ...props.editor, PortrainerPort: p }))
                                }} />
                            </Form.Group>

                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Пароль Portrainer</Form.Label>
                                <Form.Control type="password" placeholder="***" value={props.editor.PortrainerAdminPassword} onChange={(e) => props.setEditor(({ ...props.editor, PortrainerAdminPassword: e.target.value }))} />
                            </Form.Group>
                        </>}

                        <h2 className="mt-5">Связь</h2>

                        <Form.Check // prettier-ignore
                            className="mt-4"
                            type="switch"
                            label='Локальный сервер'
                            checked={props.editor.LocalServer}
                            onChange={(e) => props.setEditor(({ ...props.editor, LocalServer: e.target.checked }))}
                        />

                        {!props.editor.LocalServer && <>
                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Хост</Form.Label>
                                <Form.Control placeholder="Например: localhost" value={props.editor.Host} required onChange={(e) => props.setEditor(({ ...props.editor, Host: e.target.value }))} />
                            </Form.Group>

                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Имя пользователя</Form.Label>
                                <Form.Control placeholder="Например: root" value={props.editor.Username} required onChange={(e) => props.setEditor(({ ...props.editor, Username: e.target.value }))} />
                            </Form.Group>

                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Пароль SSH</Form.Label>
                                <Form.Control type="password" placeholder="***" value={props.editor.SshPassword} onChange={(e) => props.setEditor(({ ...props.editor, SshPassword: e.target.value }))} />
                            </Form.Group>


                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Ключ SSH</Form.Label>
                                <Form.Control as="textarea" rows={6} value={sshKey} readOnly size="sm" />
                            </Form.Group>

                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Команда для записи ключа SSH</Form.Label>
                                <Form.Control value={`ssh-copy-id ${props.editor.Username}@${props.editor.Host}`} readOnly />
                            </Form.Group>

                            <Form.Group className="has-validation w-100 mt-4">
                                <Form.Label>Порт для связи между микросервисами и оркестратором</Form.Label>
                                <Form.Control placeholder="9090" value={props.editor.RemoteWsPort} type="number" required onChange={(e) => {
                                    var p = parseInt(e.target.value)
                                    if (p && !isNaN(p) && p > 0 && p < 65535) props.setEditor(({ ...props.editor, RemoteWsPort: p }))
                                }} />
                            </Form.Group>

                        </>}

                        <div className="d-flex mt-3" style={{ gap: '1rem' }}>
                            <Button variant="primary" type="submit">Сохранить</Button>
                            <Button variant="secondary-outline" onClick={() => props.setEditor(undefined)}>Отмена</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}