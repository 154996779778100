import { Badge, Col, Container, Row } from "react-bootstrap";
import AdminTemplate from "../AdminTemplate";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from '../../models/AuthContextProvider';
import moment from 'moment-timezone'; // Используйте moment-timezone
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart, ResponsiveContainer, Brush } from 'recharts';
import { loadavg } from "os";
import { formatBytes, formatUnits } from "../../models/Format";
import * as Icon from 'react-bootstrap-icons';

// Модель для информации о контейнере
interface ContainerInfoViewModel {
    ServerId: string;
    InputInterfaceId: string | null;
    InputInterfaceName: string | null;
    OutputInterfaceName: string | null;
    OutputInterfaceId: string | null;
    Image: string;
    Created: string;
    State: string;
    Status: string;
    Name: string;
}

// Компонент для отображения информации о контейнерах
export function ContainerInfo() {
    const context = useContext(AuthContext);
    const [containers, setContainers] = useState<ContainerInfoViewModel[]>([]); // Состояние для контейнеров
    const [loading, setLoading] = useState(false); // Состояние для загрузки данных
    const [favorites, setFavorites] = useState<string[]>([]);
    const [showAll, setShowAll] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchContainerInfo = async () => {
            setLoading(true);
            try {
                const containerData = await Promise.all(context.environment.processingServers.map(async (server) => {
                    const response = await fetch(`/Statistics/ContainerInfo/${server.Id}`);
                    const data = await response.json() as ContainerInfoViewModel[];
                    console.log(`ContainerInfo: Получены данные для сервера ${server.Id}:`, data); // Логирование полученных данных
                    if ((data as any).error) return [];
                    return data;
                }));
                const flatted = containerData.flat();
                console.log('Данные с серверов', flatted)
                setContainers(flatted);
            } catch (error) {
                context.showMessage(`Ошибка загрузки данных: ${error}`, true);
                console.error(`ContainerInfo: Ошибка загрузки данных:`, error); // Логирование ошибки
            } finally {
                setLoading(false);
            }
        };
        if (context.environment.processingServers.length > 0) {
            fetchContainerInfo();
        }
        // Загрузка состояния showAll из localStorage
        const storedShowAll = localStorage.getItem('showAll_cont');
        if (storedShowAll) {
            setShowAll(JSON.parse(storedShowAll));
        }

        // Загрузка списка избранных favorites из localStorage
        const storedFavorites = localStorage.getItem('favorites_cont');
        if (storedFavorites) {
            setFavorites(JSON.parse(storedFavorites));
        }
    }, [context.environment.processingServers]); // Зависимость от processingServers

    useEffect(() => {
        // Сохранение состояния showAll в localStorage
        localStorage.setItem('showAll_cont', JSON.stringify(showAll));

        // Сохранение списка избранных favorites в localStorage
        localStorage.setItem('favorites_cont', JSON.stringify(favorites));
    }, [showAll, favorites]);

    const toggleFavorite = (containerId: string) => {
        const updatedFavorites = favorites.includes(containerId)
            ? favorites.filter((favId) => favId !== containerId)
            : [...favorites, containerId];
        setFavorites(updatedFavorites);
    };

    const filteredContainers = showAll
        ? containers
        : containers.filter((container) => favorites.includes(container.Name));

    return <div>
        {loading && <div>Загрузка данных...</div>}
        {!loading && context.environment.processingServers.map((server, index) => {
            var serverContainers = containers.filter(s => s.ServerId == server.Id)
            if (serverContainers.length == 0) return <></>
            return (
                <>
                    <div key={index} className="my-4 bg-white rounded-3 p-4">
                        <h1 className="mt-3 ">{server.Title}</h1>
                        <h2 className="mb-5">{serverContainers.length} {getIntegrationInterfacesText(serverContainers.length)}</h2>
                        <div className="form-check form-switch mb-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="showAllToggleCont"
                                checked={showAll}
                                onChange={() => setShowAll(!showAll)}
                            />
                            <label className="form-check-label" htmlFor="showAllToggleCont">
                                {showAll ? 'Показать все' : 'Показать избранное'}
                            </label>
                        </div>

                        <div className="d-grid gap-3" style={{ gridTemplateColumns: '1fr' }}>
                            {filteredContainers.map((container) => (<div className="rounded-3 p-4 d-grid gap-3" style={{ backgroundColor: '#eff1ff', gridTemplateColumns: '33rem 1fr' }} key={`container-${container.Name}`}>
                                <div>
                                    <table className="table table-transparent table-borderless m-0">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "15rem" }}>Имя входного интерфейса</th>
                                                <td style={{ width: "12vw" }}>{container.InputInterfaceName || container.Name}</td>
                                            </tr>

                                            {container.OutputInterfaceName && (
                                                <tr>
                                                    <th>Имя выходного интерфейса</th>
                                                    <td>
                                                        {container.OutputInterfaceName}
                                                        {/* <span className="badge bg-secondary">{container.OutputInterfaceId}</span> */}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th>Дата создания</th>
                                                <td>{moment.tz(container.Created, 'UTC').local().format('DD.MM.YYYY HH:mm')}</td>
                                            </tr>
                                            <tr>
                                                <th>Состояние</th>
                                                <td>
                                                    <Badge bg={containerStateBadgeStyle(container.State)}>
                                                        {container.State}
                                                    </Badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Статус</th>
                                                <td>{container.Status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="position-relative">
                                    <div className="position-absolute top-0 end-0 d-flex gap-1">
                                        {container.InputInterfaceId && <div className="bg-white rounded-2 p-2 d-flex"
                                            onClick={() => navigate(`/dev/input/${container.InputInterfaceId}`)}
                                            style={{ cursor: 'pointer', zIndex: 1000 }}>
                                            <Icon.Gear
                                                width={20}
                                                style={{
                                                    color: '#1c1c1e',
                                                }} />
                                        </div>}

                                        <div className="bg-white rounded-2 p-2 d-flex"
                                            onClick={() => toggleFavorite(container.Name)}
                                            style={{ cursor: 'pointer', zIndex: 1000 }}>
                                            <Icon.StarFill
                                                width={20}
                                                style={{
                                                    color: favorites.includes(container.Name) ? 'gold' : 'transparent',
                                                    stroke: '#1c1c1e', // Цвет обводки
                                                    strokeWidth: 1,  // Толщина обводки
                                                }} />
                                        </div>


                                    </div>

                                    <ContainerChart container={container} />
                                </div>
                            </div>))}
                        </div>
                    </div></>)
        })}
    </div>
}

interface ContainerStats {
    Id: number;
    Datetime: string;
    NetworkBytesReceived: number;
    NetworkBytesSent: number;
    CPUPercentage: number;
    MemoryUsageBytes: number;
    MemoryLimitBytes: number;
    MemoryPercentage: number;
    BlockIOReadBytes: number;
    BlockIOWriteBytes: number;
    ProcessIdCount: number;
    OutputDatasTotal: number;
    OutputDatasFailure: number;
}

function ContainerChart(props: { container: ContainerInfoViewModel }) {
    const [containerStats, setContainerStats] = useState<ContainerStats[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await fetch(`/Statistics/ContainerStatData/${props.container.ServerId}?containerName=${window.encodeURIComponent(props.container.Name)}`);
                const data = await response.json() as ContainerStats[];
                setContainerStats(data)
            } catch (e) {
                setError(`Ошибка загрузки данных: ${e}`);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) return <div>Загрузка данных...</div>;
    if (error) return <div>{error}</div>;
    if (containerStats.length == 0) return <div>Нет данных</div>

    const tooltop = () => <>
        <Tooltip
            formatter={(value, name, props) => {
                if (['OutputDatasTotal', 'OutputDatasFailure', 'ProcessIdCount'].includes(props.dataKey as string))
                    return [`${typeof (value) === 'number' ? formatUnits(value) : value}`, `${name}`];
                if (['CPUPercentage', 'MemoryPercentage'].includes(props.dataKey as string))
                    return [`${typeof (value) === 'number' ? Math.round(value) : value}%`, `${name}`];
                return [`${typeof (value) === 'number' ? formatBytes(Math.round(value)) : value}`, `${name}`];
            }}
            labelFormatter={(label, props) => `${moment.tz(props[0]?.payload.Datetime, 'UTC').local().format('DD.MM.YYYY HH:mm')}`}
        />
        <Legend />

        <defs> {/* Определение градиента */}
            <linearGradient id="colorCpu" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#5E67FB" stopOpacity={0} />
                <stop offset="5%" stopColor="#5E67FB" stopOpacity={1} />
                <stop offset="95%" stopColor="#5E67FB" stopOpacity={1} />
                <stop offset="100%" stopColor="#5E67FB" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorMemory" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#31BD00" stopOpacity={0} />
                <stop offset="5%" stopColor="#31BD00" stopOpacity={1} />
                <stop offset="95%" stopColor="#31BD00" stopOpacity={1} />
                <stop offset="100%" stopColor="#31BD00" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorDisk" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#F3722C" stopOpacity={0} />
                <stop offset="5%" stopColor="#F3722C" stopOpacity={1} />
                <stop offset="95%" stopColor="#F3722C" stopOpacity={1} />
                <stop offset="100%" stopColor="#F3722C" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorProc" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#FFC107" stopOpacity={0} />
                <stop offset="5%" stopColor="#FFC107" stopOpacity={1} />
                <stop offset="95%" stopColor="#FFC107" stopOpacity={1} />
                <stop offset="100%" stopColor="#FFC107" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorRed" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#ED4C4C" stopOpacity={0} />
                <stop offset="5%" stopColor="#ED4C4C" stopOpacity={1} />
                <stop offset="95%" stopColor="#ED4C4C" stopOpacity={1} />
                <stop offset="100%" stopColor="#ED4C4C" stopOpacity={0} />
            </linearGradient>
        </defs>
    </>
    const height = 200;
    return <Row>
        <Col>
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart data={containerStats} key={`${props.container.Name}-CPU`}>
                    {tooltop()}
                    <YAxis
                        dataKey="CPUPercentage"
                        domain={[0, 100]}
                        hide={true}
                        tickFormatter={(value) => `${value}%`}
                    />
                    <Area
                        type="monotone"
                        dataKey="CPUPercentage"
                        name="Средняя загрузка CPU"
                        stroke="url(#colorCpu)"
                        fill="url(#colorCpu)"
                        fillOpacity={0.2}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col>
        <Col>
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart data={containerStats} key={`${props.container.Name}-Memory`}>
                    {tooltop()}
                    <YAxis
                        dataKey="MemoryPercentage"
                        domain={[0, 100]}
                        hide={true}
                        tickFormatter={(value) => `${value}%`}
                    />
                    <Area
                        type="monotone"
                        dataKey="MemoryPercentage"
                        name="Использование памяти"
                        stroke="url(#colorMemory)"
                        fill="url(#colorMemory)"
                        fillOpacity={0.2}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col>
        <Col>
            <ResponsiveContainer width="100%" >
                <AreaChart data={containerStats} key={`${props.container.Name}-ProcessIds`}>
                    {tooltop()}
                    <YAxis
                        dataKey="ProcessIdCount"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true} // Включите автоматический масштаб
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <Area
                        type="monotone"
                        dataKey="ProcessIdCount"
                        name="Запущено процессов"
                        stroke="url(#colorProc)"
                        fill="url(#colorProc)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col>
        <Col>
            <ResponsiveContainer width="100%" height={height} >
                <AreaChart data={containerStats} key={`${props.container.Name}-Disk`}>
                    {tooltop()}
                    <YAxis
                        dataKey="BlockIOReadBytes"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <YAxis
                        dataKey="BlockIOWriteBytes"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <Area
                        type="monotone"
                        dataKey="BlockIOReadBytes"
                        name="Считано"
                        stroke="url(#colorDisk)"
                        fill="url(#colorDisk)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                    <Area
                        type="monotone"
                        dataKey="BlockIOWriteBytes"
                        name="Записано"
                        stroke="url(#colorDisk)"
                        fill="url(#colorDisk)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col>
        {/* 
        <Col>
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart data={containerStats} key={`${props.container.Name}-Network`}>
                    {tooltop()}
                    <YAxis
                        dataKey="NetworkBytesReceived"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <YAxis
                        dataKey="NetworkBytesSent"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <Area
                        type="monotone"
                        dataKey="NetworkBytesReceived"
                        name="Получено"
                        stroke="url(#colorProc)"
                        fill="url(#colorProc)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                    <Area
                        type="monotone"
                        dataKey="NetworkBytesSent"
                        name="Отправлено"
                        stroke="url(#colorProc)"
                        fill="url(#colorProc)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col> */}

        <Col>
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart data={containerStats} key={`${props.container.Name}-Output`}>
                    {tooltop()}
                    <YAxis
                        dataKey="OutputDatasFailure"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <YAxis
                        dataKey="OutputDatasTotal"
                        yAxisId="procCountAxis"
                        allowDataOverflow={true}
                        hide={true}
                        tickFormatter={(value) => `${value}`}
                    />
                    <Area
                        type="monotone"
                        dataKey="OutputDatasFailure"
                        name="Ошибок"
                        stroke="url(#colorRed)"
                        fill="url(#colorRed)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                    <Area
                        type="monotone"
                        dataKey="OutputDatasTotal"
                        name="Сообщений"
                        stroke="url(#colorProc)"
                        fill="url(#colorProc)"
                        fillOpacity={0.2}
                        yAxisId="procCountAxis"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Col>

    </Row>;
}



const containerStateBadgeStyle = (state: string) => {
    switch (state) {
        case 'running':
            return 'success'; // Зелёный
        case 'created':
        case 'restarting':
            return 'warning'; // Жёлтый
        case 'paused':
        case 'exited':
        case 'dead':
            return 'danger'; // Красный
        default:
            return 'secondary'; // Серый
    }
};

const getIntegrationInterfacesText = (count: number) => {
    const cases = ['активный интеграционный интерфейс', 'активных интеграционных интерфейса', 'активных интеграционных интерфейсов'];
    if (count === 1) {
        return cases[0];
    } else if (count >= 2 && count <= 4) {
        return cases[1];
    } else {
        return cases[2];
    }
};
