import { Col, Container, Row } from "react-bootstrap";
import AdminTemplate from "./../AdminTemplate";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from './../../models/AuthContextProvider';
import moment from 'moment-timezone'; // Используйте moment-timezone
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart, ResponsiveContainer, Brush } from 'recharts';
import { loadavg } from "os";
import { formatBytes } from "./../../models/Format";
import { ContainerInfo } from "./../stats/ContainerStats";

interface AverageData {
    ServerId: string;
    Datetime: string;
    LoadAverage1m: number;
    DiskUsedPercent: number;
    MemUsedPercent: number;
    ProcessesCount: number;
}

interface SystemInfoData {
    Id: number;
    Datetime: string;
    ServerId: string;
    Cpu: string;
    Cores: number;
    MemoryTotal: number;
    MemoryUsed: number;
    MemoryFree: number;
    MemoryAvailable: number;
    DiskTotal: number;
    DiskUsed: number;
    DiskAvail: number;
    SwapTotal: number;
    SwapFree: number;
    SwapUsed: number;
    LoadAverage1m: number;
    LoadAverage5m: number;
    LoadAverage15m: number;
    Uptime: string;
    ProcessesCount: number;
}

interface RootObject {
    averageData: AverageData[];
    systemInfoData: SystemInfoData[];
    error?: string;
}

export function SystemInfo() {
    const context = useContext(AuthContext);
    const [systemInfo, setSystemInfo] = useState<RootObject | null>(null);

    useEffect(() => {
        const fetchSystemInfo = async () => {
            try {
                const response = await fetch('/Statistics/SystemInfo');
                const data = await response.json() as RootObject;
                if (data.error) throw data.error;
                setSystemInfo(data);
            } catch (error) {
                context.showMessage(`Ошибка загрузки данных: ${error}`, true, "lg");
            }
        };
        fetchSystemInfo();
    }, []);

    if (!systemInfo) return <></>

    const groupDataByN = (data: AverageData[], groupSize: number = 1) => {
        const groupedData = [];

        for (let i = 0; i < data.length; i += groupSize) {
            const group = data.slice(i, i + groupSize);
            const avgLoadAverage = group.reduce((sum, item) => sum + item.LoadAverage1m, 0) / group.length;
            const avgMemoryUsed = group.reduce((sum, item) => sum + item.MemUsedPercent, 0) / group.length;
            const avgDiskUsed = group.reduce((sum, item) => sum + item.DiskUsedPercent, 0) / group.length;
            const procCount = group.reduce((sum, item) => sum + item.ProcessesCount, 0) / group.length;


            groupedData.push({
                date: moment.tz(data[i].Datetime, 'UTC').local().valueOf(),
                loadAverage: avgLoadAverage * 100,
                memoryUsed: avgMemoryUsed * 100,
                diskUsed: avgDiskUsed * 100,
                procCount: procCount
            });
        }

        return groupedData;
    };

    {/* <XAxis dataKey="date" tickFormatter={(tick) => moment(tick).format('DD MMM HH:mm')} />
    <YAxis
    domain={[0, 100]}
    tickFormatter={(value) => `${value}%`}
    label={{ value: 'Загрузка (%)', angle: -90, position: 'insideLeft' }}
    /> 
    <CartesianGrid strokeDasharray="3 3" /> */}
    {/* <Area type="monotone" dataKey="diskUsed" name="Использование диска" stroke="#F3722C" fill="#F3722C" />
    <Area type="monotone" dataKey="memoryUsed" name="Использование памяти" stroke="#31BD00" fill="#31BD00" />
    <Area type="monotone" dataKey="loadAverage" name="Средняя загрузка CPU" stroke="#5E67FB" fill="#5E67FB" /> */}


    return <>
        {context.environment.processingServers.map(server => {
            var i = systemInfo?.systemInfoData?.find(s => s.ServerId == server.Id)
            var avgData = groupDataByN(systemInfo?.averageData?.filter(s => s.ServerId == server.Id), 5)
            console.log('avgData', server, avgData);
            return <Row className="my-4 bg-white rounded-3 p-4">
                {i && <Col md={4}>
                    <h1 className="mt-3">{server.Title}</h1>
                    <h2 className="mb-5">Информация о сервере</h2>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{ width: "15rem" }}>Процессор</th>
                                <td>{i.Cpu}<br />
                                    Ядер: {i.Cores}
                                </td>
                            </tr>
                            <tr>
                                <th>Память</th>
                                <td>
                                    Всего: {formatBytes(i.MemoryTotal)}<br />
                                    Используется: {formatBytes(i.MemoryUsed)}<br />
                                    Свободно: {formatBytes(i.MemoryFree)}
                                </td>
                            </tr>
                            <tr>
                                <th>Дисковое пространство</th>
                                <td>
                                    Всего: {formatBytes(i.DiskTotal)}<br />
                                    Используется: {formatBytes(i.DiskUsed)}<br />
                                    Доступно: {formatBytes(i.DiskAvail)}
                                </td>
                            </tr>
                            <tr>
                                <th>Swap</th>
                                <td>
                                    Всего: {formatBytes(i.SwapTotal)}<br />
                                    Используется: {formatBytes(i.SwapUsed)}<br />
                                    Доступно: {formatBytes(i.SwapFree)}
                                </td>
                            </tr>
                            <tr>
                                <th>Средняя нагрузка</th>
                                <td>
                                    1 минута: {i.LoadAverage1m}<br />
                                    5 минут: {i.LoadAverage5m}<br />
                                    15 минут: {i.LoadAverage15m}
                                </td>
                            </tr>
                            <tr>
                                <th>Время работы</th>
                                <td>{i.Uptime}</td>
                            </tr>
                            <tr>
                                <th>Процессы</th>
                                <td>{i.ProcessesCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>}
                {avgData && <Col md={8}>
                    <ResponsiveContainer width="100%" >
                        <AreaChart width={730} data={avgData}>


                            <Tooltip
                                formatter={(value, name, props) => {
                                    const date = moment(props.payload.date).format('DD.MM.YYYY HH:mm');
                                    if (props.dataKey == 'procCount') return [`${typeof (value) === 'number' ? Math.round(value) : value}`, `${name}`];
                                    return [`${typeof (value) === 'number' ? Math.round(value) : value}%`, `${name}`];
                                }}
                                labelFormatter={(label, props) => `${moment(props[0]?.payload.date).format('DD.MM.YYYY HH:mm')}`}
                            />
                            <Legend />


                            <defs> {/* Определение градиента */}
                                <linearGradient id="colorCpu" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="0%" stopColor="#5E67FB" stopOpacity={0} />
                                    <stop offset="5%" stopColor="#5E67FB" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#5E67FB" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#5E67FB" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorMemory" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="0%" stopColor="#31BD00" stopOpacity={0} />
                                    <stop offset="5%" stopColor="#31BD00" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#31BD00" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#31BD00" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorDisk" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="0%" stopColor="#F3722C" stopOpacity={0} />
                                    <stop offset="5%" stopColor="#F3722C" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F3722C" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#F3722C" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorProc" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="0%" stopColor="#FFC107" stopOpacity={0} />
                                    <stop offset="5%" stopColor="#FFC107" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#FFC107" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#FFC107" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            {/* Ось Y для "loadAverage", "memoryUsed", "diskUsed" */}
                            <YAxis
                                dataKey="loadAverage"
                                domain={[0, 100]}
                                hide={true}
                                tickFormatter={(value) => `${value}%`}
                            />
                            <YAxis
                                dataKey="memoryUsed"
                                domain={[0, 100]}
                                hide={true}
                                tickFormatter={(value) => `${value}%`}
                            />
                            <YAxis
                                dataKey="diskUsed"
                                domain={[0, 100]}
                                hide={true}
                                tickFormatter={(value) => `${value}%`}
                            />

                            {/* Ось Y для "procCount" */}
                            <YAxis
                                dataKey="procCount"
                                yAxisId="procCountAxis"
                                allowDataOverflow={true} // Включите автоматический масштаб
                                hide={true}
                                tickFormatter={(value) => `${value}`}
                            />

                            <Area
                                type="monotone"
                                dataKey="loadAverage"
                                name="Средняя загрузка CPU"
                                stroke="url(#colorCpu)"
                                fill="url(#colorCpu)"
                                fillOpacity={0.2}
                            />
                            <Area
                                type="monotone"
                                dataKey="memoryUsed"
                                name="Использование памяти"
                                stroke="url(#colorMemory)"
                                fill="url(#colorMemory)"
                                fillOpacity={0.2}
                            />
                            <Area
                                type="monotone"
                                dataKey="diskUsed"
                                name="Использование диска"
                                stroke="url(#colorDisk)"
                                fill="url(#colorDisk)"
                                fillOpacity={0.2}
                            />
                            <Area
                                type="monotone"
                                dataKey="procCount"
                                name="Запущено процессов"
                                stroke="url(#colorProc)"
                                fill="url(#colorProc)"
                                fillOpacity={0.2}
                                yAxisId="procCountAxis"
                            />
                            {/* <Brush /> */}
                        </AreaChart>
                    </ResponsiveContainer>
                </Col>}
            </Row>;
        })}
    </>
}
