import { Modal, FloatingLabel, Button, Form, Container, Row } from "react-bootstrap"
import { InputSetup, InputTypes, IntegrationSystem } from "../../models/Models"
import { AuthContext } from "../../models/AuthContextProvider";
import { useContext, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { title } from "process";

export function InputInterfaceEditor(props: {
    editor: InputSetup
    setEditor: React.Dispatch<React.SetStateAction<InputSetup | undefined>>
    reload: () => void
}) {
    let context = useContext(AuthContext);
    const [integrationSystems, setIntegrationSystems] = useState<IntegrationSystem[]>(() => context.environment.integrationSystems.filter(s => props.editor.SystemIds.includes(s.Id)));

    const update = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        context.setLoading(true);
        var isNewIntegrationSystem = false;
        try {
            // добавление всех новых интеграционных систем
            console.log('Выбранные интеграционные системы', integrationSystems)
            var systemIds = [];
            for (let i = 0; i < integrationSystems.length; i++) {
                const system = integrationSystems[i] as IntegrationSystem
                if ((system as any).customOption) {
                    var newSystemResult = await fetch(`/Systems/Save`, { method: 'POST', body: JSON.stringify({ Title: system.Title, Id: crypto.randomUUID() }), headers: { 'Content-Type': 'application/json' } });
                    const { message, error, edit } = await newSystemResult.json() as { message?: string, error?: string, edit?: IntegrationSystem };
                    if (error) throw error;
                    if (!edit) throw 'Ошибка сохранения интеграционной системы';
                    systemIds.push(edit.Id);
                    isNewIntegrationSystem = true;
                } else
                    systemIds.push(system.Id);
            }
            // сохранение
            const response = await fetch(`/Integrations/SaveInputInterface`, {
                method: 'POST', body: JSON.stringify({
                    ...props.editor,
                    SystemIds: systemIds
                } as InputSetup),
                headers: { 'Content-Type': 'application/json' }
            });
            const { message, error } = await response.json() as { message?: string, error?: string };
            if (message) {
                await props.reload()
                if (message != 'OK') context.showMessage(message, false);
                else props.setEditor(undefined)
            }
            if (error) {
                context.showMessage(error, true);
            }
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
            if (isNewIntegrationSystem) await context.reloadEnvironment()
        }
    }

    return (
        <Modal show={props.editor != undefined} onHide={() => props.setEditor(undefined)} centered size="xl">
            <Modal.Header closeButton className="mt-2">
                <div>
                    <h1 className="mt-3">{props.editor.Name}
                        <span className="badge bg-secondary ms-2">{props.editor.Tag}</span>
                        <span className="badge bg-secondary ms-2">{props.editor.Id}</span>
                    </h1>
                    <h2 className="mb-4">Входной интерфейс</h2>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <Container>
                    <Form onSubmit={update}>

                        <Form.Group className="has-validation w-100">
                            <Form.Label>Наименование</Form.Label>
                            <Form.Control placeholder="Наименование" value={props.editor.Name} required onChange={(e) => props.setEditor(({ ...props.editor, Name: e.target.value }))} />
                        </Form.Group>
                        <Form.Group className="has-validation w-100 mt-4">
                            <Form.Label>Буквенно-числовой идентификатор</Form.Label>
                            <Form.Control placeholder="Буквенно-числовой идентификатор" value={props.editor.Tag} required onChange={(e) => props.setEditor(({ ...props.editor, Tag: e.target.value }))} />
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Тип входного интерфейса</Form.Label>
                            <Form.Select onChange={(e) => props.setEditor(({ ...props.editor, InputType: e.target.value as InputTypes }))}>
                                <option value={InputTypes.Request} selected={props.editor.InputType == InputTypes.Request}>Асинхронный запрос</option>
                                <option value={InputTypes.RequestWithResponse} selected={props.editor.InputType == InputTypes.RequestWithResponse}>Синхронный запрос с ответом</option>
                            </Form.Select>
                        </Form.Group>

                        {props.editor.InputType == InputTypes.RequestWithResponse && <Form.Group className="w-100 mt-4">
                            <Form.Label>Сколько ждать ответ (для запроса с ответом), сек</Form.Label>
                            <Form.Control placeholder="Сколько ждать ответ (для запроса с ответом), сек" min={0} max={65535} type="number" value={props.editor.ResponseTimeoutSeconds} required onChange={(e) => {
                                var p = parseInt(e.target.value)
                                if (p && !isNaN(p) && p >= 0 && p < 65535)
                                    props.setEditor(({ ...props.editor, ResponseTimeoutSeconds: p }))
                            }} />
                        </Form.Group>}

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>Интеграционные системы</Form.Label>
                            <Typeahead placeholder="Интеграционные системы" className="has-validation"
                                allowNew
                                multiple
                                newSelectionPrefix="Добавить новую интеграционную систему: "
                                selected={integrationSystems}
                                options={context.environment.integrationSystems}
                                labelKey='Title'
                                onChange={(selected) => setIntegrationSystems(selected.map((tt: any) => tt as IntegrationSystem))}
                            />
                        </Form.Group>

                        <Form.Group className="w-100 mt-4">
                            <Form.Label>URL интеграции</Form.Label>
                            <Form.Control readOnly value={`${window.location.origin}/i/${props.editor.Id}`} />
                            {props.editor.Tag?.length > 0 && <Form.Control readOnly value={`${window.location.origin}/i/${props.editor.Tag}`} className="mt-2" />}
                        </Form.Group>

                        <div className="d-flex mt-3" style={{ gap: '1rem' }}>
                            <Button variant="primary" type="submit">Сохранить</Button>
                            <Button variant="secondary-outline" onClick={() => props.setEditor(undefined)}>Отмена</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}