import { NavLink, useNavigate } from 'react-router-dom';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from '../models/AuthContextProvider';

export const tableStyles: TableStyles = {
    rows: {
        style: {
            minHeight: '40px',
        }
    },
    headCells: {
        style: {
            fontWeight: '600', // override the cell padding for head cells
            fontSize: '14px'
        },
    },
    cells: {
        style: {
            fontSize: '14px'
        },
    },
};

export default function AdminTemplate({ children }: { children: React.ReactNode }) {
    let context = useContext(AuthContext);
    let navigate = useNavigate()

    return <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
        <Navbar expand="lg" className="bg-white px-3">
            <Container fluid>
                <Navbar.Brand href="/" onClick={(e) => {
                    e.preventDefault()
                    navigate('/')
                }}>
                    <img
                        src="/logo.svg"
                        height="45"
                        className="d-inline-block align-center"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>

                {context.me && <>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href='/account/settings'>{context.me?.UserName}</Nav.Link>
                            <Nav.Link href="/account/logout">Выход</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </>}
            </Container>
        </Navbar>


        <div style={{ flexGrow: '2', display: "grid", gridTemplateColumns: "100%", gridTemplateRows: "auto" }}>
            {children}
        </div>
    </div>
}

