
// Функция для форматирования размера в байтах в человекочитаемый вид
export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 байт'; // Изменено на "байт"

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ']; // Изменено на русские сокращения

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
// Функция для форматирования размера в единицах (без байт)
export const formatUnits = (units: number, decimals = 2) => {
    if (units === 0) return '0';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['', ' тыс.', ' млн.', ' млрд.', ' трлн.', ' квадрлн.', ' квинтлн.', ' секстилн.', ' септилн.'];

    const i = Math.floor(Math.log(units) / Math.log(k));

    return parseFloat((units / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}