import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import AdminTemplate from "./../AdminTemplate";
import { useNavigate } from "react-router-dom";
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from './../../models/AuthContextProvider';
import moment from 'moment-timezone';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart, ResponsiveContainer, Brush } from 'recharts';
import { loadavg } from "os";
import { formatBytes } from "./../../models/Format";
import { ContainerInfo } from "./../stats/ContainerStats";
import * as Icon from 'react-bootstrap-icons';

interface TaskQueueViewModel {
    InputSetupName: string | null;
    OutputSetupName: string | null;
    OutputSetupId: string | null;
    Key: string;
    AwaitingCount: number;
    ParallelTaskCount: number;
}

// Компонент для отображения информации о контейнерах
export function TaskQueueInfo() {
    const context = useContext(AuthContext);
    const [taskQueue, setTaskQueue] = useState<TaskQueueViewModel[]>([]);
    const [awaitingCount, setAwaitingCount] = useState(0);
    const [showAll, setShowAll] = useState(true); // Состояние для переключения отображения
    const [favorites, setFavorites] = useState<string[]>([]); // Состояние для избранных строк

    useEffect(() => {
        const fetchTaskQueue = async () => {
            try {
                const response = await fetch('/Statistics/TaskQueueAwaitingCount');
                const data = await response.json();
                if (data.error) throw data.error;
                setTaskQueue(data.TaskQueues);
                setAwaitingCount(data.AwaitingCount);
            } catch (error) {
                context.showMessage(`Ошибка загрузки данных: ${error}`, true, "lg");
            }
        };
        const storedFavorites = localStorage.getItem('favorites');
        if (storedFavorites) {
            setFavorites(JSON.parse(storedFavorites));
        }
        const storedShowAll = localStorage.getItem('showAll');
        if (storedShowAll) {
            setShowAll(JSON.parse(storedShowAll));
        }
        fetchTaskQueue();
    }, []);

    useEffect(() => {
        localStorage.setItem('showAll', JSON.stringify(showAll));
    }, [showAll]);
    const toggleFavorite = (key: string) => {
        const updatedFavorites = favorites.includes(key)
            ? favorites.filter((favKey) => favKey !== key)
            : [...favorites, key];
        setFavorites(updatedFavorites);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };

    const filteredTaskQueue = showAll
        ? taskQueue
        : taskQueue.filter((queue) => favorites.includes(queue.Key));

    return (
        <div className="bg-white rounded-3 py-4 px-4 mt-3">
            <h2 className="mb-3">Запущенные очереди</h2>

            <div className="form-check form-switch mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="showAllToggleQueue"
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                />
                <label className="form-check-label" htmlFor="showAllToggleQueue">
                    {showAll ? 'Показать все' : 'Показать избранное'}
                </label>
            </div>

            {filteredTaskQueue.length == 0 && <div>Нет очередей</div>}
            {filteredTaskQueue.length > 0 && (<>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Имя входного интерфейса</th>
                            <th>Имя выходного интерфейса</th>
                            <th>Количество сообщений в очереди</th>
                            <th>Параллельных потоков для обработки</th>
                            <th style={{width: '1rem'}}></th> {/* Пустая ячейка для иконки */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTaskQueue.map((queue) => (
                            <tr key={queue.Key}>
                                <td>{queue.InputSetupName}</td>
                                <td>
                                    {queue.OutputSetupName && (
                                        <>
                                            {queue.OutputSetupName}
                                            {/* <span className="badge bg-secondary">{queue.OutputSetupId}</span> */}
                                        </>
                                    )}
                                </td>
                                <td>{queue.AwaitingCount}</td>
                                <td>{queue.ParallelTaskCount}</td>  
                                <td>
                                    <div className="p-2 d-flex"
                                        onClick={() => toggleFavorite(queue.Key)}
                                        style={{ cursor: 'pointer', zIndex: 1000 }}>
                                        <Icon.StarFill
                                            width={17}
                                            style={{
                                                color: favorites.includes(queue.Key) ? 'gold' : 'transparent',
                                                stroke: '#1c1c1e', // Цвет обводки
                                                strokeWidth: 1,  // Толщина обводки
                                            }} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>)}
        </div>
    );
}