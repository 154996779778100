import { useCallback, useContext, useEffect, useState } from "react";
import { InputSetup, InputTypes, IntegrationSystem, OutputSetup, OutputTypes, ProcessingTypes } from "../../models/Models";
import AdminTemplate from "../AdminTemplate";
import { HeaderPage } from '../HeaderPage';
import { AuthContext } from "../../models/AuthContextProvider";
import { Button } from "react-bootstrap";
import { InputInterfaceEditor } from "./InputInterfaceEditor";
import * as Icon from 'react-bootstrap-icons';
import { OutputInterfaceEditor } from "./OutputInterfaceEditor";
import { CreateInput, CreateOutput, RemoveInput } from "./Integration";
import { useNavigate } from "react-router-dom";
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import moment from 'moment'; // Импортируйте moment для форматирования даты
import { handleDates } from "../../models/handleDates";
import { Typeahead } from "react-bootstrap-typeahead";

type IntegrationViewModel = {
    InputSetup: InputSetup
    OutputSetup: OutputSetup | undefined
}

const filtersDefaults: SearchParamsStateType = {
    FilterInputName: { type: 'string', default: '' },
    FilterOutputName: { type: 'string', default: '' },
    FilterInputSystem: { type: 'string', default: '' },
    FilterOutputSystem: { type: 'string', default: '' },
    SortColumn: { type: 'string', default: 'InputName' }, // Столбец для сортировки
    SortOrder: { type: 'string', default: 'asc' } // Порядок сортировки (asc/desc)
};

export function DevsIntegrations() {
    const navigate = useNavigate();
    const [model, setModel] = useState<IntegrationViewModel[]>([])
    const [inputEditor, setInputEditor] = useState<InputSetup | undefined>(undefined);
    const [outputEditor, setOutputEditor] = useState<OutputSetup | undefined>(undefined);
    const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
    let context = useContext(AuthContext);

    const reload = async function () {
        context.setLoading(true);
        setModel([])
        try {
            const response = await fetch(`/Integrations/Integrations`, {
                method: 'POST',
                body: JSON.stringify(filterParams),
                headers: { 'Content-Type': 'application/json' }
            });
            const rawData = await handleDates(response.json()) as any;
            if (rawData.error) throw rawData.error;
            const data = rawData as IntegrationViewModel[];
            console.log('Данные загружены', data);
            setModel(data);
        } catch (error) {
            context.showMessage(<code>{`Ошибка загрузки ${error ?? ''}`}</code>, true, 'lg');
        } finally {
            context.setLoading(false);
        }
    };
    useEffect(() => {
        reload();
    }, [
        filterParams.FilterInputName,
        filterParams.FilterOutputName,
        filterParams.SortColumn, // Добавлено поле сортировки
        filterParams.SortOrder,  // Добавлено поле порядка сортировки
        filterParams.FilterInputSystem,
        filterParams.FilterOutputSystem
    ]);
    const handleSort = (column: string) => {
        setFilterParams({
            SortColumn: column,
            SortOrder: filterParams.SortColumn === column && filterParams.SortOrder === 'asc' ? 'desc' : 'asc'
        });
    };

    return (
        <AdminTemplate>
            <div className="p-3">
                <HeaderPage title="Интеграции" />
                <div>
                    <Button onClick={() => setInputEditor(CreateInput())}>Создать входной интерфейс</Button>
                </div>
                <div className="rounded-3 overflow-hidden mt-3">
                    <table className="table m-0 align-middle table-hover">
                        <thead style={{ insetBlockStart: 0, position: 'sticky' }}>
                            <tr>
                                <th></th>
                                <th onClick={() => handleSort('InputSystem')}>
                                    Входная система
                                    {filterParams.SortColumn === 'InputSystem' && (
                                        filterParams.SortOrder === 'asc' ? <Icon.SortUp /> : <Icon.SortDown />
                                    )}
                                </th>
                                <th onClick={() => handleSort('InputName')}>
                                    Входной интерфейс
                                    {filterParams.SortColumn === 'InputName' && (
                                        filterParams.SortOrder === 'asc' ? <Icon.SortUp /> : <Icon.SortDown />
                                    )}
                                </th>
                                <th onClick={() => handleSort('OutputSystem')}>
                                    Выходная система
                                    {filterParams.SortColumn === 'OutputSystem' && (
                                        filterParams.SortOrder === 'asc' ? <Icon.SortUp /> : <Icon.SortDown />
                                    )}
                                </th>
                                <th onClick={() => handleSort('OutputName')}>
                                    Выходной интерфейс
                                    {filterParams.SortColumn === 'OutputName' && (
                                        filterParams.SortOrder === 'asc' ? <Icon.SortUp /> : <Icon.SortDown />
                                    )}
                                </th>
                                <th onClick={() => handleSort('DeployDate')}>
                                    Дата публикации
                                    {filterParams.SortColumn === 'DeployDate' && (
                                        filterParams.SortOrder === 'asc' ? <Icon.SortUp /> : <Icon.SortDown />
                                    )}
                                </th>
                                <th></th>
                            </tr>
                            <tr>
                                <th className="align-middle text-center px-2">
                                    <Icon.Funnel size={20} />
                                </th>
                                <th>
                                    <Typeahead placeholder="Фильтр..." className="has-validation"
                                        multiple
                                        selected={context.environment.integrationSystems.filter(i => filterParams.FilterInputSystem?.indexOf(i.Id) !== -1)}
                                        options={context.environment.integrationSystems}
                                        labelKey='Title'
                                        onChange={(selected) => setFilterParams({ FilterInputSystem: selected.map(i => (i as IntegrationSystem).Id).join(', ') })}
                                    />
                                </th>
                                <th>
                                    {/* Фильтр по входному интерфейсу */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Фильтр..."
                                        value={filterParams.FilterInputName}
                                        onChange={(e) => setFilterParams({ FilterInputName: e.target.value })}
                                    />
                                </th>
                                <th>
                                    <Typeahead placeholder="Фильтр..." className="has-validation"
                                        multiple
                                        selected={context.environment.integrationSystems.filter(i => filterParams.FilterOutputSystem?.indexOf(i.Id) !== -1)}
                                        options={context.environment.integrationSystems}
                                        labelKey='Title'
                                        onChange={(selected) => setFilterParams({ FilterOutputSystem: selected.map(i => (i as IntegrationSystem).Id).join(', ') })}
                                    />
                                </th>
                                <th>
                                    {/* Фильтр по выходному интерфейсу */}
                                    <input
                                        type="text"
                                        className="form-control "
                                        placeholder="Фильтр..."
                                        value={filterParams.FilterOutputName}
                                        onChange={(e) => setFilterParams({ FilterOutputName: e.target.value })}
                                    />
                                </th>
                                <th></th> {/* Пустой заголовок для столбца с датой */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {model.map(integration => (
                                <tr key={integration.InputSetup.Id}>
                                    <td></td>
                                    <td>
                                        {context.environment.integrationSystems.filter(system => integration.InputSetup.SystemIds.includes(system.Id)).map(s => s.Title).join(', ')}
                                    </td>
                                    <td className="table-editor">
                                        <div>
                                            {integration.InputSetup.Name}<br />
                                            <small>{integration.InputSetup.Tag}</small>
                                        </div>
                                        <Button variant='white' onClick={() => setInputEditor({ ...integration.InputSetup })}>
                                            <Icon.Pencil height={20} width={20} />
                                        </Button>
                                    </td>
                                    <td>
                                        {integration.OutputSetup &&
                                            <>{context.environment.integrationSystems.filter(system => integration.OutputSetup?.SystemIds.includes(system.Id)).map(s => s.Title).join(', ')}</>
                                        }
                                    </td>
                                    <td className="table-editor">
                                        {integration.OutputSetup && (
                                            <>
                                                <div>
                                                    {integration.OutputSetup?.Name}<br />
                                                    <small>{integration.OutputSetup?.Tag}</small>
                                                </div>
                                                <Button variant='white' onClick={() => {
                                                    if (integration.OutputSetup) setOutputEditor({ ...integration.OutputSetup })
                                                }}>
                                                    <Icon.Pencil height={20} width={20} />
                                                </Button>
                                            </>
                                        )}

                                        {!integration.OutputSetup && (
                                            <>
                                                <div></div>
                                                <Button variant='white' onClick={() => setOutputEditor(CreateOutput(integration.InputSetup, context))}>
                                                    <Icon.PlusLg height={20} width={20} />
                                                </Button>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {integration.OutputSetup?.DeployDate ?
                                            moment.tz(integration.OutputSetup.DeployDate, 'UTC').local().format('DD.MM.YYYY HH:mm') :
                                            '-'}
                                    </td> {/* Новая ячейка с датой */}
                                    <td>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <Button variant="white" onClick={() => navigate(`/monitor/messages?GroupBy=None&FilterInputIface=${integration.InputSetup.Id}&FilterOutputIface=${integration.OutputSetup?.Id}`)}>
                                                <Icon.Display height={20} width={20} />
                                            </Button>

                                            <Button variant="white" onClick={() => navigate(`/dev/input/${integration.InputSetup.Id}`)}>
                                                <Icon.Gear height={20} width={20} />
                                            </Button>
                                            <Button variant="white" onClick={() => {
                                                if (!window.confirm('Точно удалить?')) return;
                                                RemoveInput(context, integration.InputSetup.Id, reload)
                                            }}>
                                                <Icon.Trash3 height={20} width={20} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {inputEditor && <InputInterfaceEditor editor={inputEditor} setEditor={setInputEditor} reload={reload}></InputInterfaceEditor>}
            {outputEditor && <OutputInterfaceEditor editor={outputEditor} setEditor={setOutputEditor} reload={reload}></OutputInterfaceEditor>}
        </AdminTemplate>
    );
}