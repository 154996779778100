import { Col, Container, Row } from "react-bootstrap";
import AdminTemplate from "./AdminTemplate";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from '../models/AuthContextProvider';
import moment from 'moment-timezone'; // Используйте moment-timezone
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart, ResponsiveContainer, Brush } from 'recharts';
import { loadavg } from "os";
import { formatBytes } from "../models/Format";
import { ContainerInfo } from "./stats/ContainerStats";
import { TaskQueueInfo } from "./stats/TaskQueueInfo";
import { SystemInfo } from "./stats/SystemInfo";

type HomepageViewModel = {
    messagesErrorsCount?: number
    messagesCount?: number
    integrationsCount?: number
    integrationSystemsCount?: number
    processingTemplatesCount?: number
    error?: string
}
export function HomePage() {
    const navigate = useNavigate();
    let context = useContext(AuthContext);
    const [model, setModel] = useState<HomepageViewModel | undefined>(undefined)

    const reload = async function () {
        context.setLoading(true);
        try {
            const response = await fetch(`/Statistics/Dashboard`);
            const data = await response.json() as HomepageViewModel;
            if (data.error) throw data.error;
            setModel(data)
        } catch (error) {
            context.showMessage(`Ошибка загрузки ${error ?? ''}`, true);
        } finally {
            context.setLoading(false);
        }
    };
    useEffect(() => { reload(); }, []);

    const plitka = (title: string, clickAction: () => void, info: JSX.Element, icon?: string) => {
        if (!icon) return <></>
        return <div className="bg-white rounded-3 py-4 px-4 d-flex flex-column justify-content-between home-grid position-relative" onClick={clickAction} style={{ cursor: "pointer" }}>
            {icon && <img src={icon} className="position-absolute" style={{ width: "80px", right: "10px", bottom: "2px" }} />}
            <div className="fs-6 fw-bold">{title}</div>
            <div className="fs-6 fw-bold">{info}</div>
        </div>;
    }

    const contStyle = { gridTemplateColumns: "repeat(auto-fill, 271px)", gridTemplateRows: "repeat(auto-fill, 167px)", gap: "1.5rem" }
    return <AdminTemplate>
        <div className="p-3" style={{ backgroundColor: '#9DA2CD' }}>
            <h1 className="mt-4 mb-3 text-white">Мониторинг</h1>
            <div className="d-grid" style={contStyle}>
                {plitka('Интерфейсы и интеграции', () => navigate('/monitor/messages'),
                    <span className={model?.messagesErrorsCount == 0 ? '' : 'text-danger'}>С ошибкой:<br />{model?.messagesErrorsCount} из {model?.messagesCount}</span>,
                    '/icons/uil_rss-interface.svg')}
                {plitka('Целостность каналов', () => alert('TODO'), <span>TODO</span>)}
                {plitka('Система', () => alert('TODO'), <span>TODO</span>)}
                {plitka('Серверы обработки', () => alert('TODO'), <span>TODO</span>)}
            </div>
            <h1 className="mt-4 mb-3 text-white">Настройки</h1>
            <div className="d-grid" style={contStyle}>
                {plitka('Каналы', () => alert('TODO'), <span>TODO</span>)}
                {plitka('Серверы обработки', () => navigate('/settings/servers'), <span className="fs-3">{context.environment.processingServers.length}</span>, '/icons/pc-display-horizontal.svg')}
                {plitka('Пользователи', () => alert('TODO'), <span>TODO</span>)}
                {plitka('Системы', () => navigate('/settings/systems'), <span className="fs-3">{model?.integrationSystemsCount}</span>, '/icons/arrow-repeat.svg')}
                {plitka('Шаблоны обработки', () => navigate('/settings/processing-templates'), <span className="fs-3">{model?.processingTemplatesCount}</span>, '/icons/arrow-repeat.svg')}
                {plitka('Серверные сертификаты', () => alert('TODO'), <span>TODO</span>)}
            </div>
            <h1 className="mt-4 mb-3 text-white">Разработка</h1>
            <div className="d-grid" style={contStyle}>
                {plitka('Интеграции', () => navigate('/dev/integrations'), <span className="fs-3">{model?.integrationsCount}</span>, '/icons/integrations.svg')}
            </div>

            <SystemInfo />
            <ContainerInfo />
            <TaskQueueInfo />
        </div>
    </AdminTemplate>
}
