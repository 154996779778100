import { Link, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import AdminTemplate from "../AdminTemplate";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../models/AuthContextProvider";


const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


export function Logout() {
  let navigate = useNavigate();
  let context = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      await fetch('/Users/Logout')
      console.log("Очистка куки и localStorage")
      deleteAllCookies();
      window.localStorage.clear();
      await context.reload()
      window.location.href = "/";
    })();
  }, [])
  return (
    <AdminTemplate>
      <></>
    </AdminTemplate>
  );

}