import styles from "../components/LoginTemplate.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import AdminTemplate from "../AdminTemplate";
import { useContext, useState } from "react";
import { AuthContext } from "../../models/AuthContextProvider";



export function Lost() {
  let navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  let context = useContext(AuthContext);
  const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
  const [smsCode, setSmsCode] = useState<string>('');
  const [passwd, setPasswd] = useState<string>('');
  const [passwd2, setPasswd2] = useState<string>('');


  const confirmSmsCode = async () => {
    if (passwd != passwd2) {
      alert("Введёные пароли не совпадают")
      return
    }
    context.setLoading(true)  
    try {
      if (!(smsCode?.length > 0)) {
        context.showMessage("Введите код", true)
        return
      }
      const response = await fetch('/Users/RecoverSetPassword?' + new URLSearchParams({ username, code: smsCode, passwd }),
        {
          method: 'POST',
        })
      var { message, error } = await response.json() as {
        message?: string
        error?: string
      }
      if (message) {
        setSmsMessage(undefined)
        context.showMessage(message, false)
      }
      else {
        setSmsMessage(error ?? 'Ошибка')
      }
    } catch (e) {
      setSmsMessage('Ошибка ' + e)
    } finally {
      context.setLoading(false)
    }
  }


  return (
    <AdminTemplate>
      <Container style={{ maxWidth: '40rem' }}>
        <form className="mt-4 p-3 bg-white shadow rounded" onSubmit={async (e) => {
          e.preventDefault();
          context.setLoading(true)
          try {
            const response = await fetch('/Users/RecoverSendSms?' + new URLSearchParams({
              username: username,
              phone: phone
            }), {
              method: 'POST'
            })
            console.log('RecoverSendSms result', response)
            var { message, error } = await response.json() as {
              message?: string
              error?: string
            }
            if (message) {
              setSmsMessage(message)
            }
            else {
              context.showMessage(error ?? 'Ошибка', true)
            }
          } catch (e) {
            context.showMessage('Ошибка ' + e, true)
          } finally {
            context.setLoading(false)
          }
        }}>
          <div className='mb-3 fs-5'>Восстановление пароля</div>

          <FloatingLabel label="Логин" className="mb-3 has-validation">
            <Form.Control type="text" placeholder="Логин" required
              value={username} onChange={e => setUsername(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Телефон" className="mb-3 has-validation">
            <Form.Control type="text" placeholder="Телефон" required value={phone} onChange={e => setPhone(e.target.value)} />
          </FloatingLabel>

          <Button type="submit" variant="primary" className="mt-3 w-100">Отправить код восстановления</Button>
          <Link to="/account/login" className="btn btn-link mt-2 w-100 btn-lg">Вход</Link>
        </form>

        {smsMessage && <>
          <Modal show={smsMessage != undefined} onHide={() => setSmsMessage(undefined)} centered size="lg" backdrop="static">
            <Modal.Header>
              <Modal.Title>Введите SMS-код</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-2">{smsMessage}</div>
              <FloatingLabel label="SMS код" className="has-validation w-100 mb-3">
                <Form.Control placeholder="SMS код" value={smsCode} required
                  onChange={(e) => setSmsCode(e.target.value)} />
              </FloatingLabel>
              <FloatingLabel label="Новый пароль" className="has-validation w-100 mb-3">
                <Form.Control placeholder="Новый пароль" value={passwd} required type="password"
                  onChange={(e) => setPasswd(e.target.value)} />
              </FloatingLabel>
              <FloatingLabel label="Новый пароль ещё раз" className="has-validation w-100">
                <Form.Control placeholder="Новый пароль ещё раз" value={passwd2} required type="password"
                  onChange={(e) => setPasswd2(e.target.value)} />
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => setSmsMessage(undefined)}>Отмена</Button>
              <Button variant="primary" className="ms-2" onClick={() => confirmSmsCode()}>Отправить</Button>
            </Modal.Footer>
          </Modal>
        </>}
      </Container>
    </AdminTemplate>
  );

}