import { useNavigate } from 'react-router-dom';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';


export function HeaderPage(props: {
    title: string
    middle1?: React.ReactNode
    middle1link?: string
}) {
    let navigate = useNavigate();
    return <>
        <div className='d-flex align-items-center gap-2 mb-3 bread-crumbs' style={{ fontSize: '0.85rem' }} >
            <a onClick={() => navigate('/')}>Главная</a>
            <Icon.ChevronRight />
            {props.middle1 && <>
                <a onClick={() => props.middle1link ? navigate(props.middle1link) : navigate(-1)}>
                    {props.middle1}
                </a>
                <Icon.ChevronRight />
            </>}
            <a>{props.title}</a>
        </div>
        <h1 className='pb-2'><Icon.ArrowLeft className='me-3' onClick={() => props.middle1link ? navigate(props.middle1link) : navigate('/')} style={{ cursor: 'pointer' }} />{props.title}</h1></>;
}
